import React, {useState} from 'react';
import dayjs from 'dayjs';
import Datepicker from './Datepicker';
import Timepicker from './Timepicker';
import ProctorIconSvg from '../../Svg/ProctorIconSvg';
import PartialProctorIcon from '../../Svg/PartialProctorIcon';
import NoProctoringIcon from '../../Svg/NoProctoringIcon';
import Checkbox from '@mui/material/Checkbox';
import CircleUnchecked from '../../Svg/CircleUnchecked';
import CircleChecked from '../../Svg/CircleChecked';
import constants from '../../constants'

export default function PublishCourse(props) {


  let parsedDate, defaultData, defaultTime;

  if(props.onlyDueDate){
    const existingDateString = props.dataForUpdateDueDate.current?.oldDueDate;
    // Parse the date string with dayjs
    parsedDate = dayjs(existingDateString);
  } else {
    defaultData=dayjs();
    defaultTime=dayjs()
  }

  // Initialize state with the parsed date
  
  const [selectedDate, setSelectedDate] = useState(props.onlyDueDate? dayjs(props.data?.dueDate||dayjs()) :dayjs(props.dataForPublish?.current.duedate||/* dayjs() */null));
  const [selectedTime, setSelectedTime] = useState(props.onlyDueDate? dayjs(props.data?.dueDate||dayjs()) :dayjs(props.dataForPublish?.current.duedate||/* dayjs() */null));


  const [selectedValue, setSelectedValue] = useState(2);
  const [weightage, setWeightage] = useState(10);

  const handleCheckboxChange = (value) => {
    setSelectedValue(value);
  };

  const formattedTimestamp = dayjs(`${selectedDate.format('YYYY-MM-DD')}T${selectedTime.format('HH:mm')}`).format('YYYY-MM-DD HH:mm:ss');
  
  if(props.onlyDueDate){
      
      props.dataForUpdateDueDate.current.lpcid = props.data.lpcId;
      props.dataForUpdateDueDate.current.oldDueDate = formattedTimestamp;
      props.dataForUpdateDueDate.current.dueDateForLearnerPro = formattedTimestamp;
      props.dataForUpdateDueDate.current.dueDateForModule = formattedTimestamp; 
      props.dataForUpdateDueDate.current.moduleId = props.moduleId;
      if(props.libTypeId === constants.libTypeIds.ASSIGNMENT) {
        props.dataForPublish.current.dueDateForLearnerPro = formattedTimestamp;
        props.dataForPublish.current.duedate = formattedTimestamp;
        props.dataForPublish.current.weightage = weightage;
      }

  } else {
    props.dataForPublish.current.dueDateForLearnerPro = formattedTimestamp;
    props.dataForPublish.current.duedate = formattedTimestamp;
    props.dataForPublish.current.weightage = weightage;
  }

  // const handleDateChange = (newDate) => {
  //   const formattedDate = dayjs(newDate);
  //   setSelectedDate(formattedDate);
  //   console.log("selectedTime ",selectedTime);
    
  //   setSelectedTime(dayjs());
  // };


  const proctoringMode = [
    {
      id : 1,
      icon : <ProctorIconSvg/>,
      head : 'Complete Proctoring',
      desc : 'Image Capture + Tab Switch',
      value : 2
    },
    {
      id : 2,
      icon : <PartialProctorIcon/>,
      head : 'Partial Proctoring',
      desc : 'Tab Switch only',
      value : 1
    },
    {
      id : 3,
      icon : <NoProctoringIcon/>,
      head : 'No Proctoring',
      desc : 'Publish test without Image capture and Tab switch',
      value : 0,
    }
  ]

  return (
    <div className='publish-content-div'>
      <div className='deadline-div'>
        <div className='deadline-label-div'>Set deadline <span className='mandate'>*</span></div>
        <div className='publish_calender'>
          <Datepicker 
            selectedDate={selectedDate} 
            setSelectedDate={setSelectedDate}
          />
        </div>
        <div className='Publish_timer'>
          <Timepicker
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
          />
        </div>
      </div>
      { (props.onlyDueDate === undefined || props.libTypeId === constants.libTypeIds.ASSIGNMENT) &&
      <div className="weightage-div">
        <div className='weightage-label-div'>Set weightage</div>
        <div className='weightage-input-div'>
          <input className='weightage-input' value={weightage} onChange={(event)=>setWeightage(event.target.value)}/>
        </div>
      </div> }
      { props.onlyDueDate === undefined && <div className='test-type-div'>
        <div className='test-type-div-label'>Select monitor type</div>
        {proctoringMode.map((mode,index) => (<div key={index} className={`proctor-div ${selectedValue === mode.value ? 'checked' : ''}`} onClick={(e) => {handleCheckboxChange(mode.value);props.handleChangeProctorDetail(e,mode.value)}}>
          <div className='proctor-div-image'>{mode.icon}</div>
          <div className='proctor-div-lables'>
            <div className='proctor-div-head'>{mode.head}</div>
            <div className='proctor-div-desc'>{mode.desc}</div>
          </div>
          <div className='proctor-div-checkbox'>
            <Checkbox
              defaultChecked={selectedValue === mode.value}
              icon={<CircleUnchecked/>}
              checkedIcon={<CircleChecked/>}
              checked={selectedValue === mode.value}
              onChange={(e) => {handleCheckboxChange(mode.value);props.handleChangeProctorDetail(e,mode.value)}}
            />
          </div>
        </div>))}
      </div>
      }
    </div>
  )
}
