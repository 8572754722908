import constants from "../../../constants";
import Profile from "../Home/LandingPage/Profile";
import React, { useContext, useEffect } from "react";
import StudentTestBodyPage from "./StudentTestBodyPage";
import AlertContext from "../../../context/AlertContext";
import StudentTestQuestionPage from "./StudentTestQuestionPage";
import StudentTestVideoSection from "./StudentTestVideoSection";
import Loading from "../../Notification-Loading/Loading/Loading";
import { StudentTestPageContext } from "../../../context/StudentTestPageContext";
import StudentTestPageQuestionUpdateHooks from "./StudentTestPageQuestionUpdateHooks";
import ZoomStudentView from "../../Zoom/ZoomStudentView";
import { getDataFromStorage } from "../../../util";

export default function StudentTestContainer() {
  const Alert = useContext(AlertContext);
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  const StudentTestPageQuestionUpdateHks = StudentTestPageQuestionUpdateHooks();

  useEffect(() => {
    if (
      StudentTestPageCxt.curObj !== null &&
      StudentTestPageCxt.curObj !== undefined &&
      Object.keys(StudentTestPageCxt.curObj).length > 0 &&
      StudentTestPageCxt.curObj.hasOwnProperty("review") &&
      StudentTestPageCxt.curObj.review === -1
    ) {
      StudentTestPageCxt.setShowReview(false);
    }

    if (
      StudentTestPageCxt.curObj !== null &&
      StudentTestPageCxt.curObj !== undefined &&
      Object.keys(StudentTestPageCxt.curObj).length > 0 &&
      StudentTestPageCxt.curObj.hasOwnProperty("review") &&
      StudentTestPageCxt.curObj.review === 0
    ) {
      StudentTestPageCxt.setShowReview(true);
    }
  }, [StudentTestPageCxt.curObj]);

  useEffect(() => {
    StudentTestPageCxt.setCurObj(Alert.queObj);
  }, [Alert.queObj, StudentTestPageCxt.videoQuestionAttempt]);

  /* changing currentScore score  */

  useEffect(() => {
    if (Alert.isAdmin) {
      Alert.setAnsStats(StudentTestPageCxt.ansStats);
      StudentTestPageCxt.setCurrentScore(Alert.totalLearnerScore);
      Alert.setBackupTestData(Alert.comingObj);
    }
  }, [Alert, StudentTestPageCxt]);

  useEffect(() => {
    if (Alert.isAdmin) {
      StudentTestPageCxt.setCurrentScore(Alert.totalLearnerScore);
    }
  }, [Alert, StudentTestPageCxt]);

  /* assign question for list view */

  useEffect(() => {
    if (
      Alert.hasOwnProperty("comingObj") &&
      Alert.comingObj.hasOwnProperty("sections") &&
      Alert.comingObj.sections?.length > 0 &&
      StudentTestPageCxt.sectionObj !== undefined &&
      StudentTestPageCxt.sectionObj.length > 0
    ) {
      StudentTestPageCxt.setSectionObj(Alert.comingObj.sections[0]);

      if (Alert.comingObj.sections?.length > 1) {
        StudentTestPageCxt.setNextSec(true);
      }
    }
  }, [Alert, StudentTestPageCxt]);

  useEffect(() => {
    let noOfQue = 0;

    if (
      Alert.mcqAndDesTestAndLpId.status === constants.PROGRESS_STATUS.EVALUATED
    ) {
      if (
        Alert.hasOwnProperty("backupTestDataq") &&
        Object.keys(Alert.comingObj).length !== 0
      ) {
        for (let i = 0; i < Alert.comingObj.sections?.length; i++) {
          noOfQue = noOfQue + Alert.comingObj.sections[i].questions.length;
        }

        if (Alert.backupTestDataq?.current.sections?.length === 1) {
          StudentTestPageCxt.setNextSec(false);
          StudentTestPageCxt.setPrevSec(false);
        }
      }
    }

    if (
      Alert.hasOwnProperty("comingObj") &&
      Alert.comingObj.hasOwnProperty("sections")
    ) {
      if (Alert.comingObj.sections?.length > 1) {
        StudentTestPageCxt.setNextSec(true);
      }

      StudentTestPageCxt.setSectionObj(Alert.comingObj.sections[0]);

      if (
        Alert.comingObj.sections?.length > 1 &&
        Alert.comingObj.sections[0].questions.length > 1
      ) {
        if (
          Alert.comingObj.sections?.length === Alert.secInd + 1 &&
          Alert.comingObj.sections[Alert.secInd].questions.length ===
            Alert.queInd + 1
        ) {
          Alert.setNextButton(false);
          Alert.setPrevButton(false);
        } else {
          Alert.setNextButton(true);
          Alert.setPrevButton(false);
        }
      }

      if (
        Alert.comingObj.sections?.length === 1 &&
        Alert.comingObj.sections[0].questions.length === 1
      ) {
        Alert.setNextButton(false);
        Alert.setPrevButton(false);
      }
    }
  }, [Alert.comingObj]);

  useEffect(() => {
    if (
      Alert.mcqAndDesTestAndLpId.status ===
        constants.PROGRESS_STATUS.UNLOCKED ||
      Alert.mcqAndDesTestAndLpId.status ===
        constants.PROGRESS_STATUS.REATTEMPT ||
      Alert.mcqAndDesTestAndLpId.status ===
        constants.PROGRESS_STATUS.QN_CONTINUE
    ) {
      StudentTestPageQuestionUpdateHks.questionUpdate(
        Alert.secInd,
        StudentTestPageCxt.sRender,
        Alert.comingObj
      );
    }
  }, [StudentTestPageCxt.mulRevch]);

  useEffect(() => {
    let sections;

    if (
      Alert.mcqAndDesTestAndLpId.status === constants.PROGRESS_STATUS.EVALUATED
    ) {
      if (StudentTestPageCxt.selSec === "all") {
        sections = Alert.comingObj.sections;
      } else {
        sections = Alert.comingObj?.sections?.filter(
          (sect, sindex) => sindex === StudentTestPageCxt.selSec
        );
      }

      StudentTestPageCxt.setFSections(JSON.parse(JSON.stringify(sections)));
    } else {
      if (StudentTestPageCxt.selSec === "all") {
        sections = Alert.comingObj?.sections?.filter(
          (sect, sindex) => sindex === 0
        );
      } else {
        sections = Alert.comingObj?.sections?.filter(
          (sect, sindex) => sindex === StudentTestPageCxt.selSec
        );
      }
    }

    StudentTestPageCxt.setFSections(sections);

    if (
      Alert.comingObj.sections?.length > 1 &&
      Alert.comingObj.sections?.length > StudentTestPageCxt.selSec + 1
    ) {
      StudentTestPageCxt.setNextSec(true);
      StudentTestPageCxt.setPrevSec(true);
    }

    if (Alert.comingObj.sections?.length === StudentTestPageCxt.selSec + 1) {
      if (Alert.comingObj.sections.length === 1) {
        StudentTestPageCxt.setNextSec(false);
        StudentTestPageCxt.setPrevSec(false);
      } else {
        StudentTestPageCxt.setNextSec(false);
        StudentTestPageCxt.setPrevSec(true);
      }
    }

    if (StudentTestPageCxt.selSec === 0) {
      if (Alert.comingObj.sections.length === 1) {
        StudentTestPageCxt.setNextSec(false);
        StudentTestPageCxt.setPrevSec(false);
      } else {
        StudentTestPageCxt.setNextSec(true);
        StudentTestPageCxt.setPrevSec(false);
      }
    }
  }, [StudentTestPageCxt.selSec, Alert.comingObj]);

  return (
    <div className="Mcq-desc-div-wrapper">
      {!StudentTestPageCxt.videoQuestionAttempt ||
      Alert.isAdmin ||
      (StudentTestPageCxt.videoQuestionAttempt &&
        (Alert.mcqAndDesTestAndLpId.status ===
          constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
          Alert.mcqAndDesTestAndLpId.status ===
            constants.PROGRESS_STATUS.EVALUATED)) ? (
        <div className="Mcq-desc-div">
          <div className="Mcq-desc-mainpage-div">
            <StudentTestBodyPage />
          </div>
          <div className="Mcq-desc-question-page-div">
            <StudentTestQuestionPage />
          </div>
        </div>
      ) : (
        !Alert.isAdmin && (
          <>
            <StudentTestVideoSection
              showTimer={StudentTestPageCxt.showTimer}
              setShowTimer={StudentTestPageCxt.setShowTimer}
              setHideSubmitVideoQn={Alert.setHideSubmitVideoQn}
              showtransition={StudentTestPageCxt.showtransition}
              setshowtransition={StudentTestPageCxt.setshowtransition}
              setVdoQnTimelimit={StudentTestPageCxt.setVdoQnTimelimit}
              setVideoQuestionAttempt={
                StudentTestPageCxt.setVideoQuestionAttempt
              }
            />
          </>
        )
      )}

      {Alert.isTakeTest && Alert.comingObj.videoqn !== 1 && (
        <Profile
          isTakeTest={Alert.isTakeTest}
          questionid={Alert.comingObj.testid}
          setCameraPopup={StudentTestPageCxt.setCameraPopup}
          cameraWarningMsg={StudentTestPageCxt.cameraWarningMsg}
          setCameraWarningMsg={StudentTestPageCxt.setCameraWarningMsg}
          libtypeid={
            [
              constants.libTypeIds.LAQ,
              constants.libTypeIds.SAQ,
              constants.libTypeIds.DESCRIPTIVE,
            ].includes(Alert.comingObj.sections[0].qnconfig[0].libtypeid)
              ? constants.libTypeIds.DESCRIPTIVE
              : constants.libTypeIds.MCQ
          }
        />
      )}
      {/* {Alert.isTakeTest && (
        <ZoomStudentView
          testname={Alert.comingObj.testname}
          learnerid={getDataFromStorage("learnerid")}
          zoomdata={JSON.stringify({
            lpid: Alert.lpId,
            // testtype: testtypeid,
            testid: Alert.comingObj.testid,
            lpconfigid: Alert.mcqAndDesTestAndLpId.lpconfigid,
            learnerid: parseInt(getDataFromStorage("learnerid")),
          })}
        />
      )} */}
      {Alert.isLoaded && <Loading />}
    </div>
  );
}
