import _ from "lodash";
import "./Report.scss";
import moment from "moment";
import Extdialog from "./Extdialog";
import writeXlsxFile from "write-excel-file";
import { AutoTextSize } from "auto-text-size";
import constants from "../../../../constants";
import Popup from "../../../CommonComponents/Popup";
import ModuleHooks from "../../../../Hooks/ModuleHooks";
import ReportHooks from "../../../../Hooks/ReportHooks";
import ReportFilterAndSearch from "./ReportFilterAndSearch";
import AlertContext from "../../../../context/AlertContext";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import HourglassBottom from "@mui/icons-material/HourglassBottom";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import Loading from "../../../Notification-Loading/Loading/Loading";

// import { StyledEngineProvider } from "@mui/material/styles";

import React, {
  useRef,
  useState,
  Fragment,
  useEffect,
  useContext,
} from "react";
import { getDataFromStorage } from "../../../../util";

const Report = (props) => {
  const {
    lpId,
    setMsg,
    setShow,
    setTitle,
    isLoaded,
    searchText,
    setIsUnAuth,
    viewColumns,
    performance,
    setIsLoaded,
    reportTopics,
    reportStatus,
    setSearchText,
    isCommonPopup,
    reportTableRef,
    setReportTopics,
    setReportStatus,
    typesAndModules,
    reportSortState,
    viewLearnerData,
    selectedColumns,
    setTypesAndModules,
    setReportSortState,
    hiddenColumnsInTable,
    currentTypeAndModules,
    setHiddenColumnsInTable,
    isReportClick,
    setIsReportClick,
  } = useContext(AlertContext);

  const { clearPopupStates, handlePopupStates, setCommonPopupBodyComponent } =
    ModuleHooks();

  const axios = useAxiosPrivate();
  const { handleOpenTestOrAssignment, capitalizeFirstLetterOfEachWord } =
    ReportHooks();

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showPopupForExtDate, setShowPopupForExtDate] = useState(false);

  const [extensionDate, setExtensionDate] = useState(
    moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
  );

  const learnerData = useRef([]);
  const tableColumns = useRef([]);
  const reportTopicsFil = useRef([]);
  const reportSelBlockValue = useRef([]);

  const [currentCellObj, setCurrentCellObj] = useState({});

  let popupdata = {};

  let initialState = {
    sortBy: reportSortState,
    globalFilter: searchText,
    hiddenColumns: hiddenColumnsInTable,
  };

  const {
    rows,
    state,
    prepareRow,
    headerGroups,
    getTableProps,
    setGlobalFilter,
    setHiddenColumns,
    getTableBodyProps,
  } = useTable(
    {
      data,
      columns,
      initialState,
    },
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter, sortBy } = state;

  const handleGetReport = (lpId) => {
    setIsLoaded(true);
    axios
      .post("node/admin/report/batch/lp", JSON.stringify({ lpid: lpId }), {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.resultCode === 1000) {
          return (async () => {
            localStorage.setItem("reportLpName", res.data.data.lpname);
            let sortedByOrderNo = await sortResDataByOrderNo(res.data.data);

            // let hiddenInActiveUser = await handleHideInActiveUser(
            //   sortedByOrderNo
            // );
            // let hiddenData = await hidePracticeModeTests(sortedByOrderNo);

            let buildData = await buildDataForShowInTable(sortedByOrderNo);
            if (reportTopics.length === 0)
              buildTopicFilData(sortedByOrderNo.modules);

            let hiddenInActiveUser = handleHideInActiveUser(
              buildData.learnersData,
              viewLearnerData.current
            );
            let data = JSON.parse(JSON.stringify(buildData));
            data.learnersData = hiddenInActiveUser.learnersData;
            let hData = buildHeaderCell(data);

            setColumns(hData);
            tableColumns.current = hData;

            if (viewLearnerData.current.length === 0 || isReportClick) {
              setData(hiddenInActiveUser.learnersData);
              viewLearnerData.current = hiddenInActiveUser.learnersData; //to track only active data
            } else {
              setData(hiddenInActiveUser.viewLearnerData);
              viewLearnerData.current = hiddenInActiveUser.viewLearnerData;
            }

            learnerData.current = buildData.learnersData; //to track all learner (active,inactive) data

            if (hiddenColumnsInTable.length === 0) {
              let columnList = hidePracticeModeColumns(hData);

              setHiddenColumnsInTable(columnList.columnList);
              setHiddenColumns(columnList.columnList);
              handleSetTypesFil(
                [...columnList.columnList, ...columnList.viewColumnList],
                buildData.learnersData,
                typesAndModules
              );
              viewColumns.current = columnList.viewColumnList;
            }

            setHiddenColumns(hiddenColumnsInTable);
            setTimeout(() => {
              const container = document.getElementById("bdNestContainer");
              const element = document.getElementById(
                "admin-reports-table-div"
              );

              if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                // element.scrollIntoView({ behavior: 'smooth' });

                element.scrollTop = sessionStorage.getItem(
                  "admin-reports-table-div"
                );
              }

              if (container)
                container.scrollTop = sessionStorage.getItem("bdNestContainer");
              setIsReportClick(false);
            }, 1000);
            setIsReportClick(false);
          })();
        } else if (
          res.data.message.resultCode ===
          constants.RESULT_STATUS.TECHNICAL_ERROR
        ) {
          setShow(true);
          setTitle("Warning");
          setMsg(res.data.message.msg);
        } else {
          setShow(true);
          setTitle("Error");
          setMsg("Something went wrong. Please try again later.");
        }
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          setIsUnAuth(true);
          setShow(true);
          setTitle("Error !");
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      })
      .finally(() => setIsLoaded(false));
  };

  useEffect(() => {
    if (lpId) {
      handleGetReport(lpId);
    }
  }, [lpId]);

  useEffect(() => {
    setReportSortState(sortBy);
  }, [sortBy, setReportSortState]);

  const sortResDataByOrderNo = (data) => {
    let resData = JSON.parse(JSON.stringify(data));

    //first make module orderwise based on maximumorderno data

    let modules = _.sortBy(JSON.parse(JSON.stringify(resData.modules)), [
      function (o) {
        return o.maximumorderno;
      },
    ]);

    // make module data orderwise based on orderno data

    for (let module = 0; module < modules.length; module++) {
      modules[module].testdata = _.sortBy(modules[module].testdata, [
        function (o) {
          return o.orderno;
        },
      ]);
    }

    resData.modules = modules;

    return resData;
  };

  const handleHideInActiveUser = (data, viewData) => {
    let lData = JSON.parse(JSON.stringify(data)),
      viewLearnerData = [];

    let learnersData = _.filter(lData, function (learner) {
      return learner.userstatus === 1;
    });

    for (let view = 0; view < viewData.length; view++) {
      for (let learner = 0; learner < lData.length; learner++) {
        if (lData[learner].userid === viewData[view].userid)
          viewLearnerData.push(lData[learner]);
      }
    }

    return { learnersData, viewLearnerData };
  };

  const getStatus = (status, libTypeId) => {
    if (libTypeId === constants.libTypeIds.DESCRIPTIVE) {
      if (
        status === constants.PROGRESS_STATUS.LOCKED ||
        status === constants.PROGRESS_STATUS.UNLOCKED
      ) {
        return "YET TO START";
      }

      if (status === constants.PROGRESS_STATUS.IN_PROGRESS) {
        return "YET TO SUBMIT";
      }

      if (
        status === constants.PROGRESS_STATUS.QN_CONTINUE ||
        status === constants.PROGRESS_STATUS.REATTEMPT
      ) {
        return "RESUBMISSION GRANTED";
      }

      if (
        status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED ||
        status === constants.PROGRESS_STATUS.TO_BE_EVALUATED
      ) {
        return "SUBMITTED";
      }

      if (status === constants.PROGRESS_STATUS.EVALUATED) {
        return "GRADED";
      }
    }

    if (libTypeId === constants.libTypeIds.CODING_CHALLENGE) {
      if (
        status === constants.PROGRESS_STATUS.LOCKED ||
        status === constants.PROGRESS_STATUS.UNLOCKED
      ) {
        return "YET TO START";
      }

      if (status === constants.PROGRESS_STATUS.IN_PROGRESS) {
        return "YET TO SUBMIT";
      }

      if (
        status === constants.PROGRESS_STATUS.QN_CONTINUE ||
        status === constants.PROGRESS_STATUS.REATTEMPT
      ) {
        return "RESUBMISSION GRANTED";
      }

      if (status === constants.PROGRESS_STATUS.TO_BE_EVALUATED) {
        return "SUBMITTED";
      }

      if (status === constants.PROGRESS_STATUS.EVALUATED) {
        return "GRADED";
      }
    }

    if (libTypeId === constants.libTypeIds.MCQ) {
      if (
        status === constants.PROGRESS_STATUS.LOCKED ||
        status === constants.PROGRESS_STATUS.UNLOCKED
      ) {
        return "YET TO START";
      }

      if (status === constants.PROGRESS_STATUS.IN_PROGRESS) {
        return "YET TO SUBMIT";
      }

      if (
        status === constants.PROGRESS_STATUS.QN_CONTINUE ||
        status === constants.PROGRESS_STATUS.REATTEMPT
      ) {
        return "RESUBMISSION GRANTED";
      }

      if (status === constants.PROGRESS_STATUS.EVALUATED) {
        return "GRADED";
      }
    }

    if (libTypeId === constants.libTypeIds.ASSIGNMENT) {
      if (status === constants.PROGRESS_STATUS.LOCKED) {
        return "YET TO START";
      }

      if (
        status === constants.PROGRESS_STATUS.UNLOCKED ||
        status === constants.PROGRESS_STATUS.IN_PROGRESS
      ) {
        return "YET TO SUBMIT";
      }

      if (status === constants.PROGRESS_STATUS.REATTEMPT) {
        return "RESUBMISSION GRANTED";
      }

      if (status === constants.PROGRESS_STATUS.TO_BE_EVALUATED) {
        return "SUBMITTED";
      }

      if (status === constants.PROGRESS_STATUS.EVALUATED) {
        return "GRADED";
      }
    }
  };

  const getTestSummary = (headeraccessor, libTypeId, ldata) => {
    let testheader = formataAndCreateAccessor(headeraccessor),
      templd = JSON.parse(JSON.stringify(ldata));

    const map1 = new Map();

    for (let i = 0; i < templd.length; i++) {
      if (libTypeId === constants.libTypeIds.MCQ) {
        if (
          templd[i][testheader].status === constants.PROGRESS_STATUS.LOCKED ||
          templd[i][testheader].status === constants.PROGRESS_STATUS.UNLOCKED
        ) {
          templd[i][testheader].status = constants.PROGRESS_STATUS.LOCKED;
        }

        if (
          templd[i][testheader].status ===
            constants.PROGRESS_STATUS.QN_CONTINUE ||
          templd[i][testheader].status === constants.PROGRESS_STATUS.REATTEMPT
        ) {
          templd[i][testheader].status = constants.PROGRESS_STATUS.REATTEMPT;
        }

        if (
          templd[i][testheader].status === constants.PROGRESS_STATUS.EVALUATED
        ) {
          templd[i][testheader].status = constants.PROGRESS_STATUS.EVALUATED;
        }

        if (
          templd[i][testheader].status === constants.PROGRESS_STATUS.IN_PROGRESS
        ) {
          templd[i][testheader].status = constants.PROGRESS_STATUS.IN_PROGRESS;
        }

        if (
          templd[i][testheader].status ===
          constants.PROGRESS_STATUS.TO_BE_EVALUATED
        ) {
          templd[i][testheader].status =
            constants.PROGRESS_STATUS.TO_BE_EVALUATED;
        }
      } else if (libTypeId === constants.libTypeIds.DESCRIPTIVE) {
        if (
          templd[i][testheader].status === constants.PROGRESS_STATUS.LOCKED ||
          templd[i][testheader].status === constants.PROGRESS_STATUS.UNLOCKED
        ) {
          templd[i][testheader].status = constants.PROGRESS_STATUS.LOCKED;
        }

        if (
          templd[i][testheader].status ===
            constants.PROGRESS_STATUS.QN_CONTINUE ||
          templd[i][testheader].status === constants.PROGRESS_STATUS.REATTEMPT
        ) {
          templd[i][testheader].status = constants.PROGRESS_STATUS.REATTEMPT;
        }

        if (
          templd[i][testheader].status ===
            constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
          templd[i][testheader].status ===
            constants.PROGRESS_STATUS.PARTIAL_EVALUATED
        ) {
          templd[i][testheader].status =
            constants.PROGRESS_STATUS.TO_BE_EVALUATED;
        }
      } else if (libTypeId === constants.libTypeIds.ASSIGNMENT) {
      } else if (libTypeId === constants.libTypeIds.CODING_CHALLENGE) {
        if (
          templd[i][testheader].status === constants.QUESTION_STATUS.RESUBMIT ||
          templd[i][testheader].status === constants.QUESTION_STATUS.CONTINUE
        ) {
          templd[i][testheader].status = constants.QUESTION_STATUS.RESUBMIT;
        }
      }

      if (map1.has(templd[i][testheader]?.status)) {
        let statusonj = map1.get(templd[i][testheader].status);

        statusonj.count = statusonj.count + 1;
        map1.set(templd[i][testheader].status, statusonj);
      } else {
        if (templd[i][testheader])
          map1.set(templd[i][testheader].status, {
            name: getStatus(templd[i][testheader].status, libTypeId),
            count: 1,
          });
      }
    }

    if (!map1.has(constants.PROGRESS_STATUS.LOCKED))
      map1.set(constants.PROGRESS_STATUS.LOCKED, {
        count: 0,
        name: "YET TO START",
      });

    if (!map1.has(constants.PROGRESS_STATUS.REATTEMPT))
      map1.set(constants.PROGRESS_STATUS.REATTEMPT, {
        count: 0,
        name: "RESUBMISSION GRANTED",
      });

    if (!map1.has(constants.PROGRESS_STATUS.EVALUATED))
      map1.set(constants.PROGRESS_STATUS.EVALUATED, {
        count: 0,
        name: "GRADED",
      });

    if (!map1.has(constants.PROGRESS_STATUS.TO_BE_EVALUATED))
      map1.set(constants.PROGRESS_STATUS.TO_BE_EVALUATED, {
        name: "SUBMITTED",
        count: 0,
      });

    let str = " ";

    for (const [key, value] of map1) {
      str = str + value.count + " - " + value.name + "\n";
    }

    return str;
  };

  const buildHeaderCell = (data) => {
    let hData = JSON.parse(JSON.stringify(data.tableColumns));
    let lData = JSON.parse(JSON.stringify(data.learnersData));

    if (data.batchHasCC) {
      try {
        for (let i = 0; i < hData.length; i++) {
          if (hData[i].id !== "Name" && !hData[i].id.includes("Grand")) {
            for (
              let tableColumn = 0;
              tableColumn < hData[i].columns.length;
              tableColumn++
            ) {
              if (!hData[i].columns[tableColumn].id.includes("Total")) {
                for (
                  let j = 0;
                  j < hData[i].columns[tableColumn].columns.length;
                  j++
                ) {
                  if (
                    hData[i].columns[tableColumn].columns[j] &&
                    !hData[i].columns[tableColumn].columns[j].id.includes(
                      "Total"
                    )
                  ) {
                    for (
                      let k = 0;
                      k <
                      hData[i].columns[tableColumn].columns[j].columns.length;
                      k++
                    ) {
                      let header =
                        hData[i].columns[tableColumn].columns[j].columns[k]
                          .Header;
                      hData[i].columns[tableColumn].columns[j].columns[k].Cell =
                        ({ value, row, column }) =>
                          buildCell(value, row.original[column.id], column);
                      hData[i].columns[tableColumn].columns[j].columns[
                        k
                      ].Header = (
                        <div
                          className="table-header-th"
                          title={getTestSummary(
                            hData[i].columns[tableColumn].columns[j].columns[k]
                              .id,
                            hData[i].columns[tableColumn].columns[j].columns[k]
                              .libTypeId,
                            lData
                          )}
                          style={{ cursor: "pointer" }}
                        >
                          {header}
                        </div>
                      );
                    }
                  }
                }
              }
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        for (let i = 0; i < hData.length; i++) {
          if (hData[i].id !== "Name" && !hData[i].id.includes("Grand")) {
            for (
              let tableColumn = 0;
              tableColumn < hData[i].columns.length;
              tableColumn++
            ) {
              let header = hData[i].columns[tableColumn].Header;

              if (!hData[i].columns[tableColumn].id.includes("Total"))
                hData[i].columns[tableColumn].Cell = ({ value, row, column }) =>
                  buildCell(value, row.original[column.id], column);
              hData[i].columns[tableColumn].Header = (
                <div
                  className="table-header-th"
                  title={getTestSummary(
                    hData[i].columns[tableColumn].id,
                    hData[i].columns[tableColumn].libTypeId,
                    lData
                  )}
                  style={{ cursor: "pointer" }}
                >
                  {header}
                </div>
              );
            }
          }
        }
      } catch (err) {}
    }

    return hData;
  };

  const hidePracticeModeColumns = (data) => {
    let tableData = JSON.parse(JSON.stringify(data)),
      columnList = [],
      viewColumnList = [];

    for (let i = 0; i < tableData.length; i++) {
      if (i > 0 && tableData[i].columns) {
        let practiceCount = 0;

        for (let column = 0; column < tableData[i].columns.length; column++) {
          if (
            !tableData[i].columns[column].id.includes("Total") &&
            tableData[i].columns[column].testMode ===
              constants.MODE.PRACTICE_MODE
          ) {
            practiceCount++;
            columnList.push(tableData[i].columns[column].id);
            if (tableData[i].columns[column].columns) {
              findColumnObject(
                tableData[i].columns[column].columns,
                columnList
              );
            }
          }

          viewColumnList.push(tableData[i].columns[column].id);

          if (tableData[i].columns[column].columns) {
            findColumnObject(
              tableData[i].columns[column].columns,
              viewColumnList
            );
          }
        }

        if (
          !tableData[i].id.includes("Grand") &&
          tableData[i].id !== "Name" &&
          tableData[i].columns.length - 1 === practiceCount
        ) {
          findColumnObject(tableData[i].columns, columnList);
        }

        if (
          tableData[i].id !== "Name" &&
          tableData[i].columns.length === 1 &&
          !tableData[i].id.includes("Grand")
        )
          tableData.splice(i, 1);
      }
    }

    viewColumnList = _.difference(viewColumnList, columnList);

    return { columnList, viewColumnList };
  };

  const findColumnObject = (columns, columnList) => {
    for (let column = 0; column < columns.length; column++) {
      if (columns[column].columns) {
        findColumnObject(columns[column].columns, columnList);
      } else {
        columnList.push(columns[column].id);
      }
    }
  };

  const handleSetTypesFil = (viewColumns, data, typesAndModules) => {
    let viewColumnList = [...viewColumns];
    let lData = JSON.parse(JSON.stringify(data)),
      tmpTypes = ["active", "confidence", "performance", "inactive"],
      hasPractice = false;

    for (let i = 0; i < viewColumnList.length; i++) {
      if (lData[0][formataAndCreateAccessor(viewColumnList[i])])
        tmpTypes.push(
          lData[0][formataAndCreateAccessor(viewColumnList[i])].filterattributes
            .type
        );
      hasPractice = true;
    }

    if (hasPractice) tmpTypes.push("practice");

    let unique = [...new Set(tmpTypes)];
    let oldTypesAndModules = JSON.parse(JSON.stringify(typesAndModules));
    let newTypesAndModules = _.map(oldTypesAndModules, (typeAndModule) => {
      if (!unique.includes(typeAndModule.value)) typeAndModule.disabled = true;
      return typeAndModule;
    });

    currentTypeAndModules.current = newTypesAndModules;
    setTypesAndModules(newTypesAndModules);
  };

  const buildTopicFilData = (modules) => {
    let moduleScore = 0,
      topicsFilData = [],
      columnList = [];

    for (let module = 0; module < modules.length; module++) {
      let moduleFilData = {
          children: [],
          checked: false,
          disabled: false,
          label: modules[module].mname,
          value: formataAndCreateAccessor(
            `${modules[module].mid + modules[module].mname}`
          ),
        },
        tests = modules[module].testdata;

      columnList.push(
        formataAndCreateAccessor(
          `${modules[module].mid + modules[module].mname}`
        )
      );

      for (let test = 0; test < tests.length; test++) {
        moduleScore += tests[test].targetscore;

        let libType = tests[test].libtype,
          testFilData = {
            children: [],
            checked: false,
            disabled: false,
            label: tests[test].name,
            value: formataAndCreateAccessor(
              `${tests[test].id + tests[test].name}`
            ),
          };

        columnList.push(
          formataAndCreateAccessor(`${tests[test].id + tests[test].name}`)
        );

        if (libType === constants.libTypeIds.CODING_CHALLENGE) {
          let topics = tests[test].topics,
            testScore = 0;

          for (let topic = 0; topic < topics.length; topic++) {
            // let questionsCount = topics[topic].questions?.length ?? 0,
            //   qnScore = topics[topic].targetscore / questionsCount;
            let qns = topics[topic].questions;
            testScore += topics[topic].targetscore;

            for (let qn = 0; qn < qns.length; qn++) {
              testFilData.children.push({
                checked: false,
                disabled: false,
                label: `Q${qn + 1} [${qns[0].targetscore}]`,
                value: formataAndCreateAccessor(
                  tests[test].id +
                    tests[test].name +
                    topics[topic].topicname +
                    "Q" +
                    (qn + 1)
                ),
              });

              columnList.push(
                formataAndCreateAccessor(
                  tests[test].id +
                    tests[test].name +
                    topics[topic].topicname +
                    "Q" +
                    (qn + 1)
                )
              );
            }

            if (topic === topics.length - 1) {
              testFilData.children.push({
                checked: false,
                disabled: false,
                label: `Total [${testScore}]`,
                value: formataAndCreateAccessor(
                  `${tests[test].id + tests[test].name}Total`
                ),
              });

              columnList.push(
                formataAndCreateAccessor(
                  `${tests[test].id + tests[test].name}Total`
                )
              );
            }
          }

          testFilData.label += ` [${testScore}]`;
          moduleFilData.children.push(testFilData);
        } else {
          delete testFilData.children;
          moduleFilData.children.push(testFilData);
        }

        if (test === tests.length - 1) {
          moduleFilData.children.push({
            checked: false,
            disabled: false,
            label: `Total [${moduleScore}]`,
            value: formataAndCreateAccessor(
              `${modules[module].mid + modules[module].mname}TestTotal`
            ),
          });

          columnList.push(
            formataAndCreateAccessor(
              `${modules[module].mid + modules[module].mname}TestTotal`
            )
          );
        }
      }

      topicsFilData.push(moduleFilData);

      if (module === modules.length - 1) {
        topicsFilData.push({
          checked: false,
          disabled: false,
          value: "GrandTotal",
          label: "Grand Total",
        });

        columnList.push("GrandTotal");
      }
    }

    reportTopicsFil.current = topicsFilData;
    setReportTopics(topicsFilData);
  };

  const checkBatchHasCC = (modules) => {
    for (let i = 0; i < modules.length; i++) {
      for (let j = 0; j < modules[i].testdata.length; j++) {
        if (
          modules[i].testdata[j].libtype ===
          constants.libTypeIds.CODING_CHALLENGE
        )
          return true;
      }
    }

    return false;
  };

  const buildDataForShowInTable = async (data) => {
    let hiddenData = JSON.parse(JSON.stringify(data)),
      batchHasCC = checkBatchHasCC(hiddenData.modules),
      tableColumns = [],
      accessor = "Name";

    if (batchHasCC) {
      tableColumns.push({
        id: "Name",
        Header: "Name",
        columns: [
          {
            Header: "",
            id: "Namel2",
            columns: [
              {
                Header: "",
                id: "Namel3",
                columns: [
                  {
                    Header: "",
                    id: accessor,
                    accessor: "name.fullname",
                    Cell: ({ value, row, column }) => (
                      <div className="report-learner-name">{value}</div>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      });
    } else {
      tableColumns.push({
        id: "Name",
        Header: "Name",
        columns: [
          {
            id: accessor,
            accessor: "name.fullname",
            Header: () => <div className="header-hide">{"Name"}</div>,
            Cell: ({ value, row, column }) => (
              <div className="report-learner-name">{value}</div>
            ),
          },
        ],
      });
    }

    // try {

    let learnerData = buildLearnerData(hiddenData, tableColumns, batchHasCC);

    return learnerData;

    // } catch (err) {
    //   console.error(err);
    // }
  };

  function handleGetCellColor(percentage) {
    if (performance) {
      if (percentage > 90 && percentage <= 100) {
        return "#77DD77";
      } else if (percentage > 75 && percentage <= 90) {
        return "#BEFD73";
      } else if (percentage > 60 && percentage <= 75) {
        return "#FDFD96";
      } else if (percentage > 50 && percentage <= 60) {
        return "#FF9899";
      } else if (percentage <= 50) {
        return "#FF6961";
      }
    } else {
      return "#F2F4F7";
    }
  }

  function formataAndCreateAccessor(accessor, property) {
    let tempaccessor = accessor
      ?.replaceAll(" ", "")
      ?.replaceAll("[", "")
      ?.replaceAll("]", "")
      ?.replaceAll(".", "")
      ?.replaceAll("-", "");

    if (property === null || property === undefined) {
      return tempaccessor;
    }

    tempaccessor = tempaccessor + "." + property;

    return tempaccessor;
  }

  const buildLearnerData = (data, tableColumns, batchHasCC) => {
    let hiddenData = JSON.parse(JSON.stringify(data)),
      learnersData = JSON.parse(JSON.stringify(hiddenData.learnerdata)),
      modules = JSON.parse(JSON.stringify(hiddenData.modules));

    // topicsFilData = [];

    for (let ld = 0; ld < learnersData.length; ld++) {
      // let learnerData = JSON.parse(JSON.stringify(learnersData[ld]));

      learnersData[ld].name = {
        fullname: learnersData[ld].fname + " " + learnersData[ld].lname,
        userstatus: learnersData[ld].userstatus,
      };

      let lpScore = 0,
        learnerLpScore = 0;

      for (let module = 0; module < modules.length; module++) {
        let testData = modules[module].testdata,
          moduleColumns = {
            show: true,
            columns: [],
            Header: modules[module].mname,
            id: formataAndCreateAccessor(
              `${modules[module].mid + modules[module].mname}`
            ),
          },
          grandTotalColumn = {
            show: true,
            columns: [],
            id: `Grand Total`,
            Header: `Grand Total`,
          },
          moduleScore = 0,
          learnerModuleScore = 0;

        // let moduleFilData = {
        //   label: modules[module].mname,
        //   value: modules[module].mname,
        //   children: [],
        // };

        for (let test = 0; test < testData.length; test++) {
          let libType = testData[test].libtype;

          // if (ld === 0) {
          // let testFilData = {
          //   label: testData[test].name,
          //   value: testData[test].name,
          //   children: [],
          // };

          moduleScore += testData[test].targetscore;

          let testColumns,
            testTotalColumn = {
              show: true,
              id: `Total`,
              columns: [],
              Header: `Total`,
            },
            learnerTestScore = 0;

          if (
            testData[test].libtype === constants.libTypeIds.CODING_CHALLENGE
          ) {
            testColumns = {
              show: true,
              columns: [],
              Header: `${testData[test].name}`,
              testMode: testData[test].testmode,
              id: formataAndCreateAccessor(
                `${testData[test].id + testData[test].name}`
              ),
            };

            let topics = testData[test].topics,
              testScore = 0;

            for (let topic = 0; topic < topics.length; topic++) {
              let topicColumns = {
                  id: `${topics[topic].topicname} [${topics[topic].targetscore}]`,
                  Header: `${topics[topic].topicname} [${topics[topic].targetscore}]`,
                  columns: [],
                },
                questions = topics[topic].questions,
                // targetScore = topics[topic].targetscore / questionsCount,
                totalColumn = {
                  show: true,
                  id: `Total`,
                  columns: [],
                  Header: `Total`,
                };

              // topicFilData = {
              //   label: topics[topic].topicname,
              //   value: `${topics[topic].topicname} [${topics[topic].targetscore}]`,
              //   children: [],
              // };

              testScore += topics[topic].targetscore;
              for (let qn = 0; qn < questions.length; qn++) {
                let learnerTestData;

                try {
                  learnerTestData = getLearnerTestData(
                    learnersData[ld].testdata,
                    testData[test].id,
                    topics[topic].topicid,
                    qn,
                    questions[qn].targetscore,
                    modules[module].testdata,
                    libType,
                    questions[qn].id
                  );
                } catch (err) {
                  console.error("learnerTestData", err);
                }

                if (ld === 0) {
                  // topicFilData.children.push({
                  //   label: `Q${qn + 1}`,
                  //   value:
                  //     testData[test].id +
                  //     testData[test].name +
                  //     topics[topic].topicname +
                  //     "Q" +
                  //     (qn + 1),
                  // });

                  topicColumns.columns.push({
                    id: formataAndCreateAccessor(
                      `${
                        testData[test].id +
                        testData[test].name +
                        topics[topic].topicname +
                        "Q" +
                        (qn + 1)
                      }`,
                      null
                    ),
                    show: true,
                    libTypeId: libType,
                    Header: `Q${qn + 1} [${questions[qn].targetscore}]`,
                    accessor: formataAndCreateAccessor(
                      `${
                        testData[test].id +
                        testData[test].name +
                        topics[topic].topicname +
                        "Q" +
                        (qn + 1)
                      }`,
                      "actualscore"
                    ),
                    Cell: ({ value, row, column }) =>
                      buildCell(value, row.original[column.id], column),
                  });

                  if (qn === questions.length - 1) {
                    totalColumn.columns.push({
                      show: true,
                      Header: `[${testScore}]`,
                      id: formataAndCreateAccessor(
                        `${testData[test].id + testData[test].name}Total`,
                        null
                      ),
                      accessor: formataAndCreateAccessor(
                        `${testData[test].id + testData[test].name}Total`,
                        "actualscore"
                      ),
                      Cell: ({ value, row, column }) =>
                        buildCell(value, row.original[column.id], column),
                    });
                  }
                }

                learnerTestScore += learnerTestData.actualscore;
                learnerModuleScore += learnerTestData.actualscore;
                learnerLpScore += learnerTestData.actualscore;
                learnersData[ld][
                  formataAndCreateAccessor(
                    `${
                      testData[test].id +
                      testData[test].name +
                      topics[topic].topicname +
                      "Q" +
                      (qn + 1)
                    }`,
                    null
                  )
                ] = {
                  ...learnerTestData,
                  lname: learnersData[ld].fname + " " + learnersData[ld].lname,
                  learnerid: learnersData[ld].userid,
                  mid: modules[module].mid,
                  modName: modules[module].mname,
                  testid: testData[test].id,
                  testname: testData[test].name,
                  testduedate: testData[test].testduedate,
                  score_per: learnerTestData.actualscore
                    ? (learnerTestData.actualscore * 100) /
                      learnerTestData.targetscore
                    : 0,
                  cs_per: 0,
                  testmode: testData[test].testmode,
                  questionscount: topics[topic].questions.length,
                  repqueno: qn + 1,
                  topicid: topics[topic].topicid,
                  topicname: topics[topic].topicname,
                  accessor: formataAndCreateAccessor(
                    `${
                      testData[test].id +
                      testData[test].name +
                      topics[topic].topicname +
                      "Q" +
                      (qn + 1)
                    }`,
                    null
                  ),
                  userstatus: learnersData[ld].userstatus,
                  apiattributes: {
                    lpid: lpId,
                    testid: testData[test].id,
                    learnerid: learnersData[ld].userid,
                    testqnno: learnerTestData.testqnno,
                    questionid: learnerTestData.questionid,
                  },
                  filterattributes: {
                    type: "ccq",
                    libtype: libType,
                    testmode: testData[test].testmode,
                    hname: `${
                      testData[test].id +
                      testData[test].name +
                      topics[topic].topicname +
                      "Q" +
                      (qn + 1)
                    }`,
                  },
                };
              }

              if (topic === topics.length - 1)
                learnersData[ld][
                  formataAndCreateAccessor(
                    `${testData[test].id + testData[test].name}Total`,
                    null
                  )
                ] = {
                  lname: learnersData[ld].fname + " " + learnersData[ld].lname,
                  repqueno: questions.length + 2,
                  learnerid: learnersData[ld].userid,
                  mid: modules[module].mid,
                  modName: modules[module].mname,
                  targetscore: testScore,
                  duedate: testData[test].testduedate,
                  actualscore: learnerTestScore,
                  score_per: null,
                  credibilityscore: 0,
                  cs_per: 0,
                  status: 2,
                  libtype: 5,
                  timeleft: null,

                  testid: testData[test].id,
                  testname: testData[test].name,

                  testmode: testData[test].testmode,

                  lpconfigid: testData[test].lpconfigid,
                  userstatus: learnersData[ld].userstatus,
                  accessor: formataAndCreateAccessor(
                    `${testData[test].id + testData[test].name}Total`,
                    null
                  ),
                  apiattributes: {
                    lpid: lpId,
                    testqnno: null,
                    questionid: null,
                    learnerid: learnersData[ld].userid,
                  },
                  filterattributes: {
                    libtype: 5,
                    type: "cct",
                    testmode: testData[test].testmode,
                    hname: `${testData[test].id + testData[test].name}Total`,
                  },
                };

              if (ld === 0) {
                // testFilData.children.push(topicFilData);

                testColumns.columns.push(topicColumns);

                if (topic === topics.length - 1) {
                  testColumns.columns.push(totalColumn);
                }
              }
            }
          } else {
            let learnerTestData;

            try {
              learnerTestData = getLearnerTestData(
                learnersData[ld].testdata,
                testData[test].id,
                null,
                null,
                null,
                modules[module].testdata,
                libType
              );
            } catch (err) {
              console.error("learnerTestData", err);
            }

            if (ld === 0) {
              // delete testFilData.children;

              if (batchHasCC) {
                testColumns = {
                  Header: "",
                  show: true,
                  id: `${testData[test].id + testData[test].name}`,
                  testMode:
                    testData[test].libtype === constants.libTypeIds.ASSIGNMENT
                      ? constants.MODE.TEST_MODE
                      : testData[test].testmode,
                  columns: [
                    {
                      Header: "",
                      show: true,
                      id: `${testData[test].id}`,
                      columns: [
                        {
                          id: formataAndCreateAccessor(
                            `${testData[test].id}${testData[test].name}`,
                            null
                          ),
                          libTypeId: libType,
                          Header: `${testData[test].name} [${testData[test].targetscore}]`,
                          accessor: formataAndCreateAccessor(
                            `${testData[test].id}${testData[test].name}`,
                            "actualscore"
                          ),
                          show: true,
                          Cell: ({ value, row, column }) =>
                            buildCell(value, row.original[column.id], column),
                        },
                      ],
                    },
                  ],
                };
              } else {
                testColumns = {
                  testMode:
                    testData[test].libtype === constants.libTypeIds.ASSIGNMENT
                      ? constants.MODE.TEST_MODE
                      : testData[test].testmode,
                  id: formataAndCreateAccessor(
                    `${testData[test].id}${testData[test].name}`,
                    null
                  ),
                  libTypeId: libType,
                  Header: `${testData[test].name} [${testData[test].targetscore}]`,
                  show: true,
                  accessor: formataAndCreateAccessor(
                    `${testData[test].id}${testData[test].name}`,
                    "actualscore"
                  ),
                  Cell: ({ value, row, column }) =>
                    buildCell(value, row.original[column.id], column),
                };
              }
            }

            learnerModuleScore += learnerTestData.actualscore;
            learnerLpScore += learnerTestData.actualscore;

            learnersData[ld][
              formataAndCreateAccessor(
                `${testData[test].id}${testData[test].name}`,
                null
              )
            ] = {
              ...learnerTestData,
              lname: learnersData[ld].fname + " " + learnersData[ld].lname,
              learnerid: learnersData[ld].userid,
              mid: modules[module].mid,
              modName: modules[module].mname,
              testid: testData[test].id,
              testname: testData[test].name,
              score_per: learnerTestData.actualscore
                ? (learnerTestData.actualscore * 100) /
                  learnerTestData.targetscore
                : 0,
              cs_per: null,
              testmode: testData[test].testmode ? testData[test].testmode : 2,
              userstatus: learnersData[ld].userstatus,
              accessor: formataAndCreateAccessor(
                `${testData[test].id}${testData[test].name}`,
                null
              ),
              apiattributes: {
                learnerid: learnersData[ld].userid,
                lpid: lpId,
                testid: learnerTestData.testid,
              },
              filterattributes: {
                type:
                  learnerTestData.libtype === constants.libTypeIds.ASSIGNMENT
                    ? "assi"
                    : learnerTestData.libtype === constants.libTypeIds.MCQ
                    ? "mcq"
                    : "des",
                libtype: learnerTestData.libtype,
                hname: `${testData[test].id + testData[test].name}`,
                testmode: testData[test].testmode,
              },
            };
          }
          // }

          if (test === testData.length - 1)
            learnersData[ld][
              formataAndCreateAccessor(
                `${modules[module].mid + modules[module].mname}TestTotal`,
                null
              )
            ] = {
              actualscore: learnerModuleScore,
              filterattributes: {
                type: "moduletotals",
              },
            };

          if (ld === 0) {
            moduleColumns.columns.push(testColumns);
            testTotalColumn.Header += `[${moduleScore}]`;
            if (batchHasCC)
              testTotalColumn.columns.push({
                id: `${modules[module].mid + modules[module].mname}`,
                Header: "",
                show: true,
                columns: [
                  {
                    id: formataAndCreateAccessor(
                      `${modules[module].mid + modules[module].mname}TestTotal`,
                      null
                    ),
                    Header: "",
                    show: true,
                    accessor: formataAndCreateAccessor(
                      `${modules[module].mid + modules[module].mname}TestTotal`,
                      "actualscore"
                    ),
                    Cell: ({ value, row, column }) =>
                      buildCell(value, row.original[column.id], column),
                  },
                ],
              });
            else delete testTotalColumn.columns;
            testTotalColumn.id = formataAndCreateAccessor(
              `${modules[module].mid + modules[module].mname}TestTotal`,
              null
            );
            testTotalColumn.accessor = formataAndCreateAccessor(
              `${modules[module].mid + modules[module].mname}TestTotal`,
              "actualscore"
            );
            testTotalColumn.Cell = ({ value, row, column }) =>
              buildCell(value, row.original[column.id], column);
            // moduleFilData.children.push(testFilData);
            if (test === testData.length - 1) {
              // moduleFilData.children.push({
              //   label: `Total [${moduleScore}]`,
              //   value: `${
              //     modules[module].mid + modules[module].mname
              //   }TestTotal`,
              // });
              moduleColumns.columns.push(testTotalColumn);
            }
          }
          // }
        }

        lpScore += moduleScore;

        if (module === modules.length - 1)
          learnersData[ld][`GrandTotal`] = {
            actualscore: learnerLpScore,
            filterattributes: {
              type: "grandtotal",
            },
          };

        if (ld === 0) {
          // topicsFilData.push(moduleFilData);

          if (batchHasCC) {
            grandTotalColumn.id += `[${lpScore}]`;
            grandTotalColumn.Header += `[${lpScore}]`;
            grandTotalColumn.show = true;
            grandTotalColumn.columns.push({
              id: `${modules[module].mid}GrandTotal`,
              Header: "",
              show: true,
              columns: [
                {
                  id: `${
                    modules[module].mid + modules[module].mname
                  }GrandTotal`,
                  show: true,
                  Header: "",
                  columns: [
                    {
                      id: `GrandTotal`,
                      Header: "",
                      show: true,
                      accessor: `GrandTotal.actualscore`,
                      Cell: ({ value, row, column }) =>
                        buildCell(value, row.original[column.id], column),
                    },
                  ],
                },
              ],
            });
          } else {
            delete grandTotalColumn.columns;
            grandTotalColumn.id = `GrandTotal`;
            grandTotalColumn.Header += `[${lpScore}]`;
            grandTotalColumn.show = true;
            grandTotalColumn.accessor = `GrandTotal.actualscore`;
            grandTotalColumn.Cell = ({ value, row, column }) =>
              buildCell(value, row.original[column.id], column);
          }
          tableColumns.push(moduleColumns);
          if (module === modules.length - 1) {
            // topicsFilData.push({ label: "Grand Total", value: "GrandTotal" });
            tableColumns.push(grandTotalColumn);
          }
        }
      }
      // if (ld === 0) setTopics(topicsFilData);
    }

    return { learnersData, tableColumns, batchHasCC };
  };

  const getLearnerTestData = (
    lTestDatas,
    testid,
    topicid,
    qn,
    targetScore,
    modTestData,
    libType,
    testconfigid
  ) => {
    let found = 5;
    for (let lTd = 0; lTd < lTestDatas.length; lTd++) {
      found = 0;
      if (testid === lTestDatas[lTd].testid) {
        found = 1;
        if (libType === constants.libTypeIds.CODING_CHALLENGE) {
          if (lTestDatas[lTd].testData === null) {
            return {
              testqnno: null,
              questionid: null,
              testConfigId: testconfigid,
              status: lTestDatas[lTd].status,
              testid: lTestDatas[lTd].testid,
              duedate: lTestDatas[lTd].duedate,
              libtype: lTestDatas[lTd].libtype,
              testname: lTestDatas[lTd].testname,
              targetscore: lTestDatas[lTd].targetscore,
              actualscore: lTestDatas[lTd].actualscore
                ? lTestDatas[lTd].actualscore
                : null,
              lpconfigid: lTestDatas[lTd].lpconfigid,
              timeleft: !lTestDatas[lTd].timeleft
                ? 0
                : lTestDatas[lTd].timeleft,
            };
          } else {
            let lTopicsData = lTestDatas[lTd].testdata;
            if (lTopicsData !== null) {
              for (let top = 0; top < lTopicsData.length; top++) {
                if (lTopicsData[top].topicid === topicid) {
                  let learnerCcQns = _.sortBy(
                    lTopicsData[top].questions,
                    (qn) => qn.testqnno
                  );
                  let learnerCcTestData = getLearnerCcTestData(
                    learnerCcQns,
                    qn,
                    lTestDatas[lTd],
                    targetScore,
                    testconfigid
                  );
                  return learnerCcTestData;
                }
              }
            }
            return {
              actualscore: 0,
              testqnno: null,
              timeleft: null,
              questionid: null,
              targetscore: targetScore,
              testConfigId: testconfigid,
              testid: lTestDatas[lTd].testid,
              duedate: lTestDatas[lTd].duedate,
              libtype: lTestDatas[lTd].libtype,
              testname: lTestDatas[lTd].testname,
              lpconfigid: lTestDatas[lTd].lpconfigid,
              status: constants.PROGRESS_STATUS.LOCKED,
              credibilityscore: lTestDatas[lTd].credibilityScore,
            };
          }
        } else {
          let score = lTestDatas[lTd].actualscore
            ? lTestDatas[lTd].actualscore
            : 0;
          return {
            targetscore: lTestDatas[lTd].targetscore,
            actualscore: score,
            duedate: lTestDatas[lTd].duedate,
            status: lTestDatas[lTd].status,
            testid: lTestDatas[lTd].testid,
            credibilityscore: lTestDatas[lTd].credibilityScore
              ? lTestDatas[lTd].credibilityScore
              : 0,
            lpconfigid: lTestDatas[lTd].lpconfigid,
            libtype: lTestDatas[lTd].libtype,
            testname: lTestDatas[lTd].testname,
            displaytext:
              (lTestDatas[lTd].hasOwnProperty("actualscore") &&
                lTestDatas[lTd].actualscore == null) ||
              !lTestDatas[lTd].hasOwnProperty("actualscore")
                ? "TBE"
                : score,
            timeleft: lTestDatas[lTd].timeleft,
          };
        }
      }
    }

    if (found === 0) {
      for (let i = 0; i < modTestData.length; i++) {
        if (modTestData[i].id === testid) {
          if (libType === constants.libTypeIds.CODING_CHALLENGE) {
            return {
              targetscore: modTestData[i].targetscore,
              actualscore: 0,
              duedate: modTestData[i].duedate,
              status: 0,
              testid: modTestData[i].id,
              testqnno: null,
              questionid: null,
              lpconfigid: modTestData[i].lpconfigid,
              libtype: modTestData[i].libtype,
              testname: modTestData[i].name,
              timeleft: 0,
            };
          } else {
            return {
              targetscore: modTestData[i].targetscore,
              actualscore: 0,
              duedate: modTestData[i].testduedate,
              status: 0,
              testid: modTestData[i].id,
              credibilityscore: 0,
              lpconfigid: modTestData[i].lpconfigid,
              libtype: modTestData[i].libtype,
              testname: modTestData[i].name,
              displaytext: "-",
              timeleft: 0,
            };
          }
        }
      }
    }
  };

  const getLearnerCcTestData = (
    learnerCcQns,
    qn,
    lTestData,
    targetScore,
    testconfigid
  ) => {
    if (learnerCcQns.length > qn) {
      return {
        testid: lTestData.testid,
        duedate: lTestData.duedate,
        libtype: lTestData.libtype,
        testConfigId: testconfigid,
        testname: lTestData.testname,
        status: learnerCcQns[qn].status,
        lpconfigid: lTestData.lpconfigid,
        timeleft: learnerCcQns[qn].timeleft,
        testqnno: learnerCcQns[qn].testqnno,
        questionid: learnerCcQns[qn].questionid,
        targetscore: learnerCcQns[qn].targetscore,
        credibilityscore: learnerCcQns[qn].credibilityScore,
        actualscore:
          learnerCcQns[qn].actualscore !== null
            ? learnerCcQns[qn].actualscore
            : 0,
      };
    } else {
      return {
        targetscore: targetScore,
        actualscore: 0,
        duedate: lTestData.duedate,
        status: constants.PROGRESS_STATUS.LOCKED,
        testid: lTestData.testid,
        testqnno: null,
        questionid: null,
        credibilityscore: lTestData.credibilityscore
          ? lTestData.credibilityscore
          : null,
        lpconfigid: lTestData.lpconfigid,
        libtype: lTestData.libtype,
        testname: lTestData.testname,
        timeleft: null,
      };
    }
  };

  const handleExtendDate = (row) => {
    setExtensionDate(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
    // setShowPopupForExtDate(true);
    setCurrentCellObj(row);

    /* for popup */
    popupdata.showpopup = true;
    popupdata.title = "Set new deadline";
    popupdata.closebtn = true;
    popupdata.iscomponent = true;
    popupdata.wobtn = true;
    popupdata.component = (
      <Extdialog
        dateshow={true}
        cellobj={row}
        setextensiondate={setExtensionDate}
        extensiondate={extensionDate}
        setdateshow={setShowPopupForExtDate}
        updateDataAfterExtDate={updateDataAfterExtDate}
        lpid={lpId}
      />
    );
    popupdata.singlebtnsize = "small";
    popupdata.primbtntxt = "Save";
    popupdata.secbtntxt = "Cancel";
    // popupdata.primbtnfunt = handleSaveDueDate;
    popupdata.secbtnfunt = clearPopupStates;
    handlePopupStates(popupdata);
  };

  useEffect(() => {
    if (isCommonPopup)
      setCommonPopupBodyComponent(
        <Extdialog
          dateshow={true}
          cellobj={currentCellObj}
          extensiondate={extensionDate}
          setextensiondate={setExtensionDate}
          setdateshow={setShowPopupForExtDate}
          updateDataAfterExtDate={updateDataAfterExtDate}
          lpid={lpId}
        />
      );
  }, [extensionDate]);

  const buildCell = (value, row, column) => {
    let dueDate = moment(row?.duedate);
    let today = moment();
    let result = today.diff(dueDate, "minutes");

    return (
      <Fragment>
        {row?.status === constants.PROGRESS_STATUS.EVALUATED ? (
          <div
            style={{
              cursor: "pointer",
              background: handleGetCellColor(row.score_per),
            }}
            title="Evaluated"
            onClick={
              getDataFromStorage("role") == constants.Roles.hr
                ? doNothing
                : () => openTest(row, column.id)
            }
          >
            {row?.actualscore === null ? 0 : row?.actualscore}
          </div>
        ) : row?.status === constants.PROGRESS_STATUS.TO_BE_EVALUATED ? (
          <div
            style={{ cursor: "pointer" }}
            title="To be evaluated"
            onClick={
              getDataFromStorage("role") == constants.Roles.hr
                ? doNothing
                : () => openTest(row, column.id)
            }
          >
            TBE
          </div>
        ) : row?.status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED ? (
          <div
            style={{ cursor: "pointer" }}
            title="Partially evaluated"
            onClick={
              getDataFromStorage("role") == constants.Roles.hr
                ? doNothing
                : () => openTest(row, column.id)
            }
          >
            {row?.actualscore === null ? 0 : row?.actualscore}
            <HourglassBottom style={{ color: "#F55533" }} />
          </div>
        ) : result > 0 ? (
          <div
            style={{ cursor: "pointer" }}
            title={
              "Post Due Date\n Duedate: " +
              moment(row.duedate).format("YYYY-MM-DD HH:mm:ss")
            }
            onClick={
              getDataFromStorage("role") == constants.Roles.hr
                ? doNothing
                : () => handleExtendDate(row)
            }
          >
            PDD
          </div>
        ) : row?.status === constants.PROGRESS_STATUS.QN_CONTINUE ? (
          <div
            style={{ cursor: "pointer" }}
            title={`Retake with time left\n Duedate: ${moment(
              row.duedate
            ).format("YYYY-MM-DD HH:mm:ss")}
            \n Timeleft : ${row.timeleft < 60 ? "0" : row.timeleft / 60} min  ${
              row.timeleft % 60
            } sec `}
            onClick={
              getDataFromStorage("role") == constants.Roles.hr
                ? doNothing
                : () => openTest(row, column.id)
            }
          >
            CON
          </div>
        ) : row?.status === constants.PROGRESS_STATUS.IN_PROGRESS ? (
          <div
            style={{ cursor: "pointer" }}
            title="In progress"
            onClick={
              getDataFromStorage("role") == constants.Roles.hr
                ? doNothing
                : () => openTest(row, column.id)
            }
          >
            PRO
          </div>
        ) : row?.status === constants.PROGRESS_STATUS.REATTEMPT ? (
          <div
            style={{ cursor: "pointer" }}
            title={`Retake\n Duedate: ${moment(row.duedate).format(
              "YYYY-MM-DD HH:mm:ss"
            )}`}
            onClick={
              getDataFromStorage("role") == constants.Roles.hr
                ? doNothing
                : () => openTest(row, column.id)
            }
          >
            RES
          </div>
        ) : row?.status === constants.PROGRESS_STATUS.LOCKED ||
          row?.status === constants.PROGRESS_STATUS.UNLOCKED ? (
          <div title="Yet to start">-</div>
        ) : (
          <div>{row?.actualscore === null ? 0 : row?.actualscore}</div>
        )}
      </Fragment>
    );
  };

  const getCurrentLearnersTestData = (row, name) => {
    let learnersData = JSON.parse(JSON.stringify(learnerData.current)) || [],
      propertyValues = [],
      filteredlearners;

    if (row.libtype === constants.libTypeIds.CODING_CHALLENGE) {
      for (const property in learnersData[0]) {
        if (
          property === "college" ||
          property === "email" ||
          property === "branch"
        ) {
          continue;
        }
        if (
          row.testid === learnersData[0][property].testid &&
          learnersData[0][property].filterattributes.type === "ccq"
        ) {
          propertyValues.push(property);
        }
      }
      filteredlearners = learnersData.map((obj) => {
        return _.pick(obj, propertyValues);
      });
    }
    if (row.libtype !== constants.libTypeIds.CODING_CHALLENGE) {
      filteredlearners = learnersData.map((obj) => {
        return _.pick(obj, [name]);
      });
      for (const property in learnersData[0]) {
        // if((property!==="id")|| (property!==="name"))
        if (
          property === "college" ||
          property === "email" ||
          property === "branch"
        ) {
          continue;
        }

        if (
          learnersData[0].hasOwnProperty(property) &&
          learnersData[0][property].hasOwnProperty("modName") &&
          learnersData[0][property].hasOwnProperty("testname") &&
          filteredlearners[0].hasOwnProperty(property) &&
          filteredlearners[0][property].hasOwnProperty("modName") &&
          filteredlearners[0][property].hasOwnProperty("testname")
        ) {
          if (
            learnersData[0][property].modName ===
              filteredlearners[0][property].modName &&
            learnersData[0][property].testname ===
              filteredlearners[0][property].testname
          ) {
            propertyValues.push(property);
          }
        }
      }
    }
    let testDataWithOutKey = _.map(learnersData, (learner) => {
      return learner[name];
    });
    let testDataWithKey = _.map(learnersData, (learner) =>
      _.pick(learner, propertyValues)
    );
    return { testDataWithKey, testDataWithOutKey };
  };

  const doNothing = () => {
    console.log("reports cant be view");
  };

  const openTest = (row, name) => {
    handleOpenTestOrAssignment(row, name);

    let topics = JSON.parse(JSON.stringify(reportTopicsFil.current));

    for (let column = 0; column < viewColumns.current.length; column++) {
      for (let topic = 0; topic < topics.length; topic++) {
        findTopicToSetChecked(topics[topic], viewColumns.current[column]);
      }
    }

    reportTopicsFil.current = topics;
    setReportTopics(topics);
  };

  const handleOnChange = (
    current,
    selected,
    fullColumns,
    typesAndModulesFilData
  ) => {
    let hideColList = [],
      viewColumnList = [],
      columns = JSON.parse(
        JSON.stringify(fullColumns ? fullColumns : tableColumns.current)
      );

    selectedColumns.current = selected;

    for (let i = 0; i < selected.length; i++) {
      findObject(columns, selected[i], viewColumnList);
    }

    if (selected.length > 0) hidecolumns(columns, hideColList);

    let tmptypes = [
        "confidence",
        "active",
        "inactive",
        "performance",
        "practice",
      ],
      lData = JSON.parse(JSON.stringify(viewLearnerData.current)),
      hasPractice = false,
      tmpTopics = [];

    if (selected.length === 0) {
      let columnList = hidePracticeModeColumns(columns);

      viewColumnList = [...columnList.viewColumnList, ...columnList.columnList];

      for (let i = 0; i < viewColumnList.length; i++) {
        if (lData.length > 0) {
          if (lData[0][formataAndCreateAccessor(viewColumnList[i])])
            tmptypes.push(
              lData[0][formataAndCreateAccessor(viewColumnList[i])]
                .filterattributes.type
            );
          if (
            lData[0][formataAndCreateAccessor(viewColumnList[i])] &&
            lData[0][formataAndCreateAccessor(viewColumnList[i])].testmode ===
              constants.MODE.PRACTICE_MODE
          )
            hasPractice = true;
          if (reportSelBlockValue.current.length > 0) {
            if (
              lData[0][formataAndCreateAccessor(viewColumnList[i])] &&
              reportSelBlockValue.current.includes(
                lData[0][formataAndCreateAccessor(viewColumnList[i])]
                  ?.filterattributes.type
              )
            ) {
              if (
                lData[0][formataAndCreateAccessor(viewColumnList[i])] &&
                (lData[0][formataAndCreateAccessor(viewColumnList[i])]
                  .testmode === constants.MODE.TEST_MODE ||
                  viewColumnList[i].toLowerCase().includes("total"))
              ) {
                tmpTopics.push(viewColumnList[i]);
              }
            }
            if (
              hasPractice &&
              reportSelBlockValue.current.includes("practice")
            ) {
              if (
                lData[0][formataAndCreateAccessor(viewColumnList[i])] &&
                lData[0][formataAndCreateAccessor(viewColumnList[i])]
                  .testmode === constants.MODE.PRACTICE_MODE
              ) {
                tmpTopics.push(viewColumnList[i]);
              }
            }
          } /* else tmpTopics.push(viewColumnList[i]); */
        }
      }

      if (reportSelBlockValue.current.length > 0) {
        let hideColumnList = _.difference(viewColumnList, tmpTopics);

        columnList.columnList = hideColumnList;
      }
      setHiddenColumnsInTable(columnList.columnList);
      setHiddenColumns(columnList.columnList);
      viewColumns.current = columnList.viewColumnList;
    } else {
      viewColumnList =
        viewColumnList.length > 0 ? viewColumnList : viewColumns.current;
      viewColumns.current = viewColumnList;

      for (let i = 0; i < viewColumnList.length; i++) {
        if (lData.length > 0) {
          if (lData[0][formataAndCreateAccessor(viewColumnList[i])])
            tmptypes.push(
              lData[0][formataAndCreateAccessor(viewColumnList[i])]
                .filterattributes.type
            );
          if (
            lData[0][formataAndCreateAccessor(viewColumnList[i])] &&
            lData[0][formataAndCreateAccessor(viewColumnList[i])].testmode ===
              constants.MODE.PRACTICE_MODE
          )
            hasPractice = true;
          if (reportSelBlockValue.current.length > 0) {
            if (
              lData[0][formataAndCreateAccessor(viewColumnList[i])] &&
              reportSelBlockValue.current.includes(
                lData[0][formataAndCreateAccessor(viewColumnList[i])]
                  .filterattributes.type
              )
            ) {
              if (
                lData[0][formataAndCreateAccessor(viewColumnList[i])] &&
                (lData[0][formataAndCreateAccessor(viewColumnList[i])]
                  .testmode === constants.MODE.TEST_MODE ||
                  viewColumnList[i].toLowerCase().includes("total"))
              ) {
                tmpTopics.push(viewColumnList[i]);
              }
            }
            if (
              hasPractice &&
              reportSelBlockValue.current.includes("practice")
            ) {
              if (
                lData[0][formataAndCreateAccessor(viewColumnList[i])] &&
                lData[0][formataAndCreateAccessor(viewColumnList[i])]
                  .testmode === constants.MODE.PRACTICE_MODE
              ) {
                tmpTopics.push(viewColumnList[i]);
              }
            }
          }
        }
      }
      if (reportSelBlockValue.current.length > 0) {
        let hideColumnList = _.difference(viewColumnList, tmpTopics);

        // if (tmpTopics.length === 0)

        hideColList = [...hideColList, ...hideColumnList];

        // else hideColList = hideColumnList;
      }
      if (hasPractice) tmptypes.push("practice");
      setHiddenColumnsInTable(hideColList);
      setHiddenColumns(hideColList);
    }
    if (lData.length > 0) {
      let unique = [...new Set(tmptypes)];
      let oldTypesAndModules = JSON.parse(
        JSON.stringify(
          typesAndModulesFilData ? typesAndModulesFilData : typesAndModules
        )
      );
      let newTypesAndModules = _.map(oldTypesAndModules, (typeAndModule) => {
        if (!unique.includes(typeAndModule.value))
          typeAndModule.disabled = true;
        return typeAndModule;
      });

      currentTypeAndModules.current = newTypesAndModules;
      setTypesAndModules(newTypesAndModules);
    }
  };

  const findTopicToSetChecked = (topic, column) => {
    if (topic.children) {
      if (topic.value === column) {
        topic.checked = true;
        setAllChildrenChecked(topic.children);
      } else {
        for (let children = 0; children < topic.children.length; children++) {
          findTopicToSetChecked(topic.children[children], column);
        }
      }
    } else {
      if (topic.value === column) topic.checked = true;
    }
  };

  const setAllChildrenChecked = (children) => {
    for (let i = 0; i < children.length; i++) {
      children[i].checked = true;
    }
  };

  const hidecolumns = (tableColumns, hideColList) => {
    for (let column = 0; column < tableColumns.length; column++) {
      if (tableColumns[column].columns) {
        hidecolumns(
          JSON.parse(JSON.stringify(tableColumns[column].columns)),
          hideColList
        );
      } else {
        if (tableColumns[column].id !== "Name")
          hideColList.push(tableColumns[column].id);
      }
    }
  };

  const findObject = (tableColumns, selected, viewColumnList, check) => {
    for (let column = 0; column < tableColumns.length; column++) {
      if (selected.hasOwnProperty("children")) {
        if (
          tableColumns[column].id !== "Name" &&
          tableColumns[column].id === selected.value
        ) {
          if (tableColumns[column].columns)
            getViewColList(tableColumns[column].columns, viewColumnList);
          else viewColumnList.push(tableColumns[column].id);
          tableColumns.splice(column, 1);
        } else {
          if (tableColumns[column].columns)
            findObject(tableColumns[column].columns, selected, viewColumnList);
        }
      } else {
        if (tableColumns[column].columns) {
          findObject(tableColumns[column].columns, selected, viewColumnList);
        } else {
          if (
            tableColumns[column].id !== "Name" &&
            tableColumns[column].id === selected.value
          ) {
            viewColumnList.push(tableColumns[column].id);
            tableColumns.splice(column, 1);
          }
        }
      }
    }
  };

  const getViewColList = (columns, viewColumnList) => {
    for (let column = 0; column < columns.length; column++) {
      if (columns[column].columns) {
        getViewColList(columns[column].columns, viewColumnList);
      } else viewColumnList.push(columns[column].id);
    }
  };

  const handleApplyStatusFilters = (current, selected) => {
    let lData = JSON.parse(JSON.stringify(viewLearnerData.current)) || [],
      columns = [...viewColumns.current] || [],
      tempLearnerData = [];

    if (current.checked) {
      setReportStatus((pre) =>
        _.map(pre, (o) => {
          if (o.value === current.value) o.checked = true;
          return o;
        })
      );

      for (let learner = 0; learner < lData.length; learner++) {
        let remove = false;
        for (let column = 0; column < columns.length; column++) {
          if (!columns[column].includes("Total")) {
            if (
              lData[learner][columns[column]]?.libtype ===
              constants.libTypeIds.ASSIGNMENT
            ) {
              // let status = lData[learner][columns[column]].status;
              // if (status !== current.value) remove = true;
              if (current.value === constants.PROGRESS_STATUS.LOCKED) {
                if (
                  lData[learner][columns[column]]?.status !==
                    constants.PROGRESS_STATUS.LOCKED &&
                  lData[learner][columns[column]]?.status !==
                    constants.PROGRESS_STATUS.UNLOCKED
                )
                  remove = true;
              } else if (current.value === constants.PROGRESS_STATUS.UNLOCKED) {
                if (
                  lData[learner][columns[column]]?.status !==
                  constants.PROGRESS_STATUS.IN_PROGRESS
                )
                  remove = true;
              } else if (
                current.value === constants.PROGRESS_STATUS.REATTEMPT
              ) {
                if (
                  lData[learner][columns[column]]?.status !==
                  constants.PROGRESS_STATUS.REATTEMPT
                )
                  remove = true;
              } else if (
                current.value === constants.PROGRESS_STATUS.TO_BE_EVALUATED
              ) {
                if (
                  lData[learner][columns[column]]?.status !==
                  constants.PROGRESS_STATUS.TO_BE_EVALUATED
                )
                  remove = true;
              } else {
                if (
                  lData[learner][columns[column]]?.status !==
                  constants.PROGRESS_STATUS.EVALUATED
                )
                  remove = true;
              }
            } else if (
              lData[learner][columns[column]]?.libtype ===
              constants.libTypeIds.MCQ
            ) {
              if (current.value === constants.PROGRESS_STATUS.LOCKED) {
                if (
                  lData[learner][columns[column]]?.status !==
                    constants.PROGRESS_STATUS.LOCKED &&
                  lData[learner][columns[column]]?.status !==
                    constants.PROGRESS_STATUS.UNLOCKED
                )
                  remove = true;
              } else if (current.value === constants.PROGRESS_STATUS.UNLOCKED) {
                if (
                  lData[learner][columns[column]].status !==
                  constants.PROGRESS_STATUS.IN_PROGRESS
                )
                  remove = true;
              } else if (
                current.value === constants.PROGRESS_STATUS.REATTEMPT
              ) {
                if (
                  lData[learner][columns[column]].status !==
                    constants.PROGRESS_STATUS.REATTEMPT &&
                  lData[learner][columns[column]].status !==
                    constants.PROGRESS_STATUS.QN_CONTINUE
                )
                  remove = true;
              } else {
                if (
                  lData[learner][columns[column]]?.status !==
                  constants.PROGRESS_STATUS.EVALUATED
                )
                  remove = true;
              }
            } else if (
              lData[learner][columns[column]]?.libtype ===
              constants.libTypeIds.DESCRIPTIVE
            ) {
              if (current.value === constants.PROGRESS_STATUS.LOCKED) {
                if (
                  lData[learner][columns[column]]?.status !==
                    constants.PROGRESS_STATUS.LOCKED &&
                  lData[learner][columns[column]].status !==
                    constants.PROGRESS_STATUS.UNLOCKED
                )
                  remove = true;
              } else if (current.value === constants.PROGRESS_STATUS.UNLOCKED) {
                if (
                  lData[learner][columns[column]]?.status !==
                  constants.PROGRESS_STATUS.IN_PROGRESS
                )
                  remove = true;
              } else if (
                current.value === constants.PROGRESS_STATUS.REATTEMPT
              ) {
                if (
                  lData[learner][columns[column]]?.status !==
                    constants.PROGRESS_STATUS.REATTEMPT &&
                  lData[learner][columns[column]]?.status !==
                    constants.PROGRESS_STATUS.QN_CONTINUE
                )
                  remove = true;
              } else if (
                current.value === constants.PROGRESS_STATUS.TO_BE_EVALUATED
              ) {
                if (
                  lData[learner][columns[column]]?.status !==
                    constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
                  lData[learner][columns[column]]?.status !==
                    constants.PROGRESS_STATUS.PARTIAL_EVALUATED
                )
                  remove = true;
              } else {
                if (
                  lData[learner][columns[column]]?.status !==
                  constants.PROGRESS_STATUS.EVALUATED
                )
                  remove = true;
              }
            } else {
              if (current.value === constants.PROGRESS_STATUS.LOCKED) {
                if (
                  lData[learner][columns[column]]?.status !==
                    constants.PROGRESS_STATUS.LOCKED &&
                  lData[learner][columns[column]]?.status !==
                    constants.PROGRESS_STATUS.UNLOCKED
                )
                  remove = true;
              } else if (current.value === constants.PROGRESS_STATUS.UNLOCKED) {
                if (
                  lData[learner][columns[column]]?.status !==
                  constants.PROGRESS_STATUS.IN_PROGRESS
                )
                  remove = true;
              } else if (
                current.value === constants.PROGRESS_STATUS.REATTEMPT
              ) {
                if (
                  lData[learner][columns[column]]?.status !==
                    constants.PROGRESS_STATUS.REATTEMPT &&
                  lData[learner][columns[column]]?.status !==
                    constants.PROGRESS_STATUS.QN_CONTINUE
                )
                  remove = true;
              } else if (
                current.value === constants.PROGRESS_STATUS.TO_BE_EVALUATED
              ) {
                if (
                  lData[learner][columns[column]]?.status !==
                  constants.PROGRESS_STATUS.TO_BE_EVALUATED
                )
                  remove = true;
              } else {
                if (
                  lData[learner][columns[column]]?.status !==
                  constants.PROGRESS_STATUS.EVALUATED
                )
                  remove = true;
              }
            }
          }
        }
        if (!remove) tempLearnerData.push(lData[learner]);
      }

      setData(tempLearnerData);
      viewLearnerData.current = tempLearnerData;
    } else {
      setReportStatus((pre) =>
        _.map(pre, (o) => {
          o.checked = false;
          return o;
        })
      );
      let hiddenInActiveUser = handleHideInActiveUser(
        learnerData.current,
        viewLearnerData.current
      );
      setData(hiddenInActiveUser.learnersData);
      viewLearnerData.current = hiddenInActiveUser.learnersData;
    }
  };

  // const includesAny = (arr, values) => values.some((v) => arr.includes(v));

  const handleApplyBlockFilters = (current, selected) => {
    let lDataForActive = JSON.parse(JSON.stringify(learnerData.current)) || [],
      selectedBlockValue = _.uniq(selected.map((val) => val.value)),
      lData = JSON.parse(JSON.stringify(viewLearnerData.current)) || [];

    if (selected.length > 0) {
      let tempLearnerData = [];

      if (
        selectedBlockValue.includes("active") ||
        selectedBlockValue.includes("inactive")
      ) {
        // if(!current.checked)selected=selected.filter(sel=>sel.value!==current.value)

        tempLearnerData = _.filter(lDataForActive, (learner) => {
          for (let i = 0; i < selected.length; i++) {
            if (selected[i].value === "active") {
              if (learner.userstatus === 1) return learner;
            } else if (selected[i].value === "inactive")
              if (learner.userstatus === 0) return learner;
          }
        });
      }
      if (
        !selectedBlockValue.includes("active") &&
        !selectedBlockValue.includes("inactive")
      ) {
        tempLearnerData = lData;
      }

      setData(tempLearnerData || []);
      viewLearnerData.current = tempLearnerData || [];
    } else {
      let hideInActiveUsers = handleHideInActiveUser(
        learnerData.current,
        viewLearnerData.current
      );
      setData(hideInActiveUsers.learnersData);
      viewLearnerData.current = hideInActiveUsers.learnersData;
    }

    let /*  tmpTypes = [
        "assi",
        "des",
        "mcq",
        "ccq",
        "cct",
        "moduletotals",
        "practice",
      ], */
      toRemove = ["active", "inactive", "performance", "confidence"],
      removedSeleBlockValue = selectedBlockValue.filter(function (el) {
        return !toRemove.includes(el);
      });

    reportSelBlockValue.current = removedSeleBlockValue;
    // if (includesAny(selectedBlockValue, tmpTypes))
    let newTypesAndModules = JSON.parse(JSON.stringify(typesAndModules));

    for (let i = 0; i < newTypesAndModules.length; i++) {
      if (selected.length > 0) {
        for (let sel = 0; sel < selected.length; sel++) {
          if (selected[sel].value === newTypesAndModules[i].value)
            newTypesAndModules[i].checked = true;
        }
      } else newTypesAndModules[i].checked = false;
    }

    currentTypeAndModules.current = newTypesAndModules;
    setTypesAndModules(newTypesAndModules);
    handleOnChange(
      null,
      selectedColumns.current,
      tableColumns.current,
      newTypesAndModules
    );

    // if(current.checked&&current.value==='performance') {
    //   setPerformance(true);
    //   let hData=buildHeaderCell({tableColumns:tableColumns.current,learnersData:lData})
    //   setColumns(hData)
    //   tableColumns.current = hData;
    // }
  };

  const getLeavesForspan = (column, span) => {
    if (column.hasOwnProperty("columns")) {
      for (var i = 0; i < column.columns.length; i++) {
        span = getLeavesForspan(column.columns[i], span);
      }
      return span;
    }
    span++;
    return span;
  };

  const getCellValue = (row) => {
    let dueDate = moment(row?.duedate);
    let today = moment();
    let result = today.diff(dueDate, "minutes");
    let value =
      row?.status === constants.PROGRESS_STATUS.EVALUATED
        ? row?.actualscore === null
          ? 0
          : row?.actualscore
        : row?.status === constants.PROGRESS_STATUS.TO_BE_EVALUATED
        ? "TBE"
        : row?.status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED
        ? row?.actualscore === null
          ? 0
          : row?.actualscore
        : result > 0
        ? "PDD"
        : row?.status === constants.PROGRESS_STATUS.QN_CONTINUE
        ? "CON"
        : row?.status === constants.PROGRESS_STATUS.IN_PROGRESS
        ? "PRO"
        : row?.status === constants.PROGRESS_STATUS.REATTEMPT
        ? "RES"
        : row?.status === constants.PROGRESS_STATUS.LOCKED ||
          row?.status === constants.PROGRESS_STATUS.UNLOCKED
        ? "-"
        : row?.actualscore === null
        ? 0
        : row?.actualscore;
    return value;
  };

  const getExcel = async (event) => {
    event.preventDefault();
    const config = {
      filename: "Report",
      sheet: {
        data: [],
      },
    };

    var columns = [{ width: 0 }];

    const dataSet = config.sheet.data;

    // review with one level nested config
    // HEADERS

    headerGroups.forEach((headerGroup, groupind) => {
      const headerRow = [];

      if (headerGroup.headers) {
        headerGroup.headers.forEach((column, ind) => {
          let calvalue;

          if (column.hasOwnProperty("columns")) {
            if (
              column.accessor === undefined &&
              (typeof column["Header"] == "function" ||
                typeof column["Header"] == "object")
            )
              calvalue = "";
            else calvalue = column.Header;
          } else {
            calvalue = column.Header;
          }

          if (column.hasOwnProperty("columns")) {
            var colspan = getLeavesForspan(column, 0);
            headerRow.push({
              value: calvalue,
              span: getLeavesForspan(column, 0),
              fontWeight: "bold",
              align: "center",
              width: 30,
              wrap: true,
              borderStyle: "dotted",
              backgroundColor: "#F2F4F7",
            });
            for (var t = 1; t < colspan; t++) {
              headerRow.push({
                value: "",
                span: 1,
                fontWeight: "bold",
                align: "center",
                width: 30,
                wrap: true,
                backgroundColor: "#F2F4F7",
                borderStyle: "dotted",
              });
            }
          } else {
            if (typeof column["Header"] == "string") {
              headerRow.push({
                value: column.Header,
                span: 1,
                fontWeight: "bold",
                align: "center",
                width: 30,
                wrap: true,
                borderStyle: "dotted",
                backgroundColor: "#F2F4F7",
              });
            } else if (typeof column["accessor"] == "string") {
              headerRow.push({
                value: column.Header,
                span: 1,
                fontWeight: "bold",
                align: "center",
                width: 30,
                wrap: true,
                borderStyle: "dotted",
                backgroundColor: "#F2F4F7",
              });
            } else if (
              typeof column["Header"] == "object" &&
              typeof column["accessor"] == "function"
            ) {
              headerRow.push({
                value: column.Header.props.children,
                span: 1,
                fontWeight: "bold",
                align: "center",
                width: 30,
                wrap: true,
                borderStyle: "dotted",
                backgroundColor: "#F2F4F7",
              });
            } else {
              headerRow.push({
                value: "",
                span: 1,
                fontWeight: "bold",
                align: "center",
                width: 30,
                wrap: true,
                borderStyle: "dotted",
                backgroundColor: "#F2F4F7",
              });
            }
          }

          if (
            ind > 0 &&
            !column.hasOwnProperty("columns") &&
            groupind === headerGroups.length - 1
          ) {
            var callength = calvalue.length + 6;

            columns.push({ width: callength });
          }
        });
      }
      if (groupind === 0) {
        headerRow.splice(1, 0, {
          value: "Branch",
          span: 1,
          fontWeight: "bold",
          align: "center",
          width: 30,
          wrap: true,
          borderStyle: "dotted",
          backgroundColor: "#F2F4F7",
        });
        headerRow.splice(1, 0, {
          value: "College",
          span: 1,
          fontWeight: "bold",
          align: "center",
          width: 30,
          wrap: true,
          borderStyle: "dotted",
          backgroundColor: "#F2F4F7",
        });
        headerRow.splice(1, 0, {
          value: "Email",
          span: 1,
          fontWeight: "bold",
          align: "center",
          width: 30,
          wrap: true,
          borderStyle: "dotted",
          backgroundColor: "#F2F4F7",
        });
      }
      if (groupind > 0) {
        headerRow.unshift({
          value: "",
          span: 1,
          fontWeight: "bold",
          align: "center",
          width: 30,
          wrap: true,
          borderStyle: "dotted",
          backgroundColor: "#F2F4F7",
        });
        headerRow.unshift({
          value: "",
          span: 1,
          fontWeight: "bold",
          align: "center",
          width: 30,
          wrap: true,
          borderStyle: "dotted",
          backgroundColor: "#F2F4F7",
        });
        headerRow.unshift({
          value: "",
          span: 1,
          fontWeight: "bold",
          align: "center",
          width: 30,
          wrap: true,
          borderStyle: "dotted",
          backgroundColor: "#F2F4F7",
        });
      }
      dataSet.push(headerRow);
    });
    // FILTERED ROWS
    if (rows.length > 0) {
      var callength = 0;
      rows.forEach((row) => {
        const dataRow = [];
        let viewColumns = [];
        row.cells?.forEach((cell) => viewColumns.push(cell.column.id));
        Object.keys(row.original).forEach((key) => {
          if (
            typeof row.original[key] === "object" &&
            row.original[key] !== null &&
            key !== "name" &&
            key !== "testdata" &&
            viewColumns.includes(key)
          ) {
            let value = getCellValue(row.original[key]);
            dataRow.push({
              value: value,
              align: "center",
            });
          }
        });
        dataRow.unshift({
          value: row.original.name.fullname,
          align: "center",
        });

        dataRow.splice(1, 0, {
          value: row.original.branch,
          align: "center",
        });
        dataRow.splice(1, 0, {
          value: row.original.college,
          align: "center",
        });
        dataRow.splice(1, 0, {
          value: row.original.email,
          align: "center",
        });
        dataSet.push(dataRow);
      });

      columns[0] = { width: callength };
      columns[columns.length - 1] = { width: 25 };
    } else {
      dataSet.push([
        {
          value: "No data",
        },
      ]);
    }

    return await writeXlsxFile(dataSet, {
      columns,
      fileName: "report.xlsx",
    });
  };

  const updateDataAfterExtDate = () => {
    let keysToAvoid = ["name", "testdata", "GrandTotal"];
    const learnersData = JSON.parse(JSON.stringify(viewLearnerData.current));
    let updatedData = _.map(learnersData, (learner) => {
      if (learner.userid === currentCellObj.learnerid) {
        if (currentCellObj.libtype === constants.libTypeIds.CODING_CHALLENGE) {
          let keys = Object.keys(learner).filter(
            (key) => !key.includes("total") && !keysToAvoid.includes(key)
          );
          for (let key = 0; key < keys.length; key++) {
            if (learner[keys[key]]?.testid === currentCellObj.testid)
              learner[keys[key]].duedate = extensionDate;
          }
        } else learner[currentCellObj.accessor].duedate = extensionDate;
      }
      return learner;
    });
    viewLearnerData.current = updatedData;
    setData(updatedData);
  };

  const scrollretain = () => {
    if (reportTableRef.current) reportTableRef.current.scrollTop = 10000;
  };

  return (
    <div id="report-main">
      <div className="reportLpName">{localStorage.getItem("reportLpName")}</div>

      <ReportFilterAndSearch
        globalFilter={globalFilter}
        setSearchText={setSearchText}
        setGlobalFilter={setGlobalFilter}
        topicFilData={reportTopics}
        typesAndModules={typesAndModules}
        status={reportStatus}
        setHiddenColumns={setHiddenColumns}
        columns={columns}
        setColumns={setColumns}
        setHiddenColumnsInTable={setHiddenColumnsInTable}
        handleOnChange={handleOnChange}
        handleApplyStatusFilters={handleApplyStatusFilters}
        handleApplyBlockFilters={handleApplyBlockFilters}
        getExcel={getExcel}
        handleGetReport={handleGetReport}
      />
      <div
        onLoad={scrollretain}
        ref={reportTableRef}
        className="scrollable-body"
        id="admin-reports-table-div"
        onScroll={(event) =>
          sessionStorage.setItem(
            "admin-reports-table-div",
            JSON.stringify(event.target.scrollTop)
          )
        }
      >
        <table {...getTableProps()}>
          <thead id="admin-reports-table-head-div">
            {headerGroups?.map((headerGroup, hgindex) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers?.map((column, index) => {
                  const { key } = column.getHeaderProps();
                  return (
                    <th
                      key={key}
                      className="report-thead-th"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className="report-thead-th-div-span">
                        <AutoTextSize
                          minFontSizePx="6"
                          maxFontSizePx="16"
                          mode="multiline"
                        >
                          {column.render("Header")}
                        </AutoTextSize>
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className="report-table-data-cell"
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* {showPopupForExtDate && (
        <StyledEngineProvider injectFirst>
          <Extdialog
            dateshow={showPopupForExtDate}
            cellobj={currentCellObj}
            setextensiondate={setExtensionDate}
            extensiondate={extensionDate}
            setdateshow={setShowPopupForExtDate}
            updateDataAfterExtDate={updateDataAfterExtDate}
            // handleExtension={handleExtension}
            // extensiondatechange={extensiondatechange}
            // handleReport={handleReport}
            lpid={lpId}
            // changetablerendered={changetablerendered}
          />
        </StyledEngineProvider>
      )} */}
      {isCommonPopup && (
        <div className="popup-overlay">
          <Popup
          // isPopup={successRes.isSuccess}
          // primaryOnclick={primaryOnclick}
          // title={"Publish the test"}
          // desc={"Select when and how you would like to publish the test"}
          // setSuccessRes={setSuccessRes}
          // popupBodyComponent={
          // 	<PublishCourse
          // 		handleChangeProctorDetail={handleChangeProctorDetail}
          // 		dataForPublish={dataForPublish}
          // 	/>
          // }
          // btnSize={"small"}
          // primBtnTxt={"Publish Test"}
          // secBtnTxt={"Cancel"}
          // popupCloseIcon={true}
          // secondaryOnclick={handleClosePopup}
          />
        </div>
      )}
      {isLoaded ? <Loading /> : null}
    </div>
  );
};

export default Report;
