import useApicall from "../useApiCall";
import constants from "../../constants";
import { useContext, useState } from "react";
import { getPreSignedUrl } from "../../util";
import useAxiosPrivate from "../useAxiosPrivate";
import AlertContext from "../../context/AlertContext";
import useUploadLargeFileToS3 from "../useUploadLargeFileToS3";
import useCommonFunctionHooks from "../useCommonFunctionHooks";

export default function CodingHeaderAdminSideHooks() {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { getAPIurl } = useApicall();
  const { deleteS3Objects } = useUploadLargeFileToS3();

  const { getHeaderDetailsForAxios, getCatchBlockDetails } =
    useCommonFunctionHooks();

    const [showAll, setShowAll] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showProctoring, setShowProctoring] = useState(false);

  const [deleteImages, setDeleteImages] = useState({
    withKey: [],
    withOutKey: [],
  });

  const handleProfileShow = () => setShowProfile(true);
  const handleProfileClose = () => setShowProfile(false);

  const handleProctoringClosePopup = () => {
    setShowProctoring(false);
    Alert.setProfile([]);
  };

  const handleApprove = () => {
    Alert.setComment("");
    Alert.setShowReject(false);
    Alert.setShowApprove(!Alert.showApprove);
  };

  const handleReject = () => {
    Alert.setComment("");
    Alert.setShowApprove(false);
    Alert.setShowReject(!Alert.showReject);
  };

  const getProctorData = () => {
    if (!showAll) {
      return Alert.profile.proctoringdatas?.filter(
        (data) => parseInt(data.probabilityofmatch) !== 100
      );
    } else {
      return Alert.profile.proctoringdatas;
    }
  };

  const getImageData = () => {
    if (!showAll) {
      const filteredData = Alert.profile.proctoringdatas?.filter(
        (data) => parseInt(data.probabilityofmatch) !== 100
      );

      return {
        ...Alert.profile,
        proctoringdatas: filteredData,
      };
    } else {
      return Alert.profile;
    }
  };

  const showSubmitButton = (status) => {
    if (
      status === constants.QUESTION_STATUS.RESUBMIT ||
      status === constants.QUESTION_STATUS.CONTINUE ||
      status === constants.QUESTION_STATUS.IN_PROGRESS
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getPayLoadForProctoring = () => {
    let payLoad = {
      lpid: Alert.showBreadCrumbsData.lpId,
      libtypeid: Alert.showBreadCrumbsData.type,
      userid: Alert.showBreadCrumbsData.learnerId,
    };

    if (
      Alert.showBreadCrumbsData.type === constants.libTypeIds.CODING_CHALLENGE
    ) {
      payLoad.testid = Alert.showBreadCrumbsData.testId;
      payLoad.qnid = Alert.showBreadCrumbsData.questionId;
      payLoad.qnno = Alert.showBreadCrumbsData.questionNo;
      payLoad.testconfigid = Alert.showBreadCrumbsData.testConfigId;
    } else {
      payLoad.qnid = Alert.showBreadCrumbsData.testId;
    }

    return JSON.stringify(payLoad);
  };

  const handleShowProctoring = () => {
    setShowProctoring(true);
    Alert.setIsLoaded(true);
    axios
      .post(
        "node/admin/userproctoring",
        getPayLoadForProctoring(),
        getHeaderDetailsForAxios()
      )
      .then(async (res) => {
        if (res.data === "") {
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: "No data available",
          });
        } else {
          let data = res.data;

          data.registerUrl = await getPreSignedUrl(
            `Proctoring/Registration/${Alert.learnerId}/${Alert.learnerId}.png`
          );
          if (data.hasOwnProperty("proctoringdatas")) {
            for (let i = 0; i < data.proctoringdatas.length; i++) {
              let filePath = `Proctoring/${Alert.showBreadCrumbsData.testId}/`;
              if (
                parseInt(Alert.showBreadCrumbsData.type) ===
                constants.libTypeIds.CODING_CHALLENGE
              )
                filePath += `${Alert.showBreadCrumbsData.questionId}/${Alert.learnerId}/`;
              else filePath += `${Alert.learnerId}/`;

              filePath += data.proctoringdatas[i].imagename;

              let url = await getPreSignedUrl(filePath);

              data.proctoringdatas[i].imagePresignedUrl = url;
            }
          }

          res.data = data;
          Alert.setProfile(res.data);
        }
      })
      .catch((err) => {
        getCatchBlockDetails(err, "proctor");
      })
      .finally(() => Alert.setIsLoaded(false));
  };

  const handleSelectImage = (checked, imageName) => {
    let details = getImageData();

    let imageNames = JSON.parse(JSON.stringify(deleteImages));

    if (checked) {
      if (imageName === "all") {
        imageNames.withKey = details.proctoringdatas.map((detail) => {
          return {
            Key: `Proctoring/${Alert.codeDetails.testid}/${Alert.codeDetails.qnid}/${Alert.learnerId}/${detail.imagename}`,
          };
        });

        imageNames.withOutKey = details.proctoringdatas.map((detail) => {
          return detail.imagename;
        });

        details.proctoringdatas = details.proctoringdatas.map((detail) => {
          detail.isChecked = true;
          return detail;
        });

        // Alert.setProfile(details);
        setDeleteImages(imageNames);
      } else {
        details.proctoringdatas = details.proctoringdatas.map((detail) => {
          if (detail.imagename === imageName) detail.isChecked = true;

          return detail;
        });
        imageNames.withKey.push({
          Key: `Proctoring/${Alert.codeDetails.testid}/${Alert.codeDetails.qnid}/${Alert.learnerId}/${imageName}`,
        });
        imageNames.withOutKey.push(imageName);
        setDeleteImages(imageNames);
        // Alert.setProfile(details);
      }
    } else {
      if (imageName === "all") {
        imageNames.withKey = [];
        imageNames.withOutKey = [];
        details.proctoringdatas = details.proctoringdatas.map((detail) => {
          detail.isChecked = false;

          return detail;
        });
      } else {
        imageNames.withKey = imageNames.withKey.filter(
          (imgName) => !imgName.Key.includes(imageName)
        );
        imageNames.withOutKey = imageNames.withOutKey.filter(
          (imgName) => imgName !== imageName
        );
        details.proctoringdatas = details.proctoringdatas.map((detail) => {
          if (detail.imagename === imageName) detail.isChecked = false;

          return detail;
        });
      }

      Alert.setProfile(details);
      setDeleteImages(imageNames);
    }
  };

  const handleDeleteImg = async (event) => {
    event.preventDefault();
    try {
      Alert.setIsLoaded(true);

      let res = await axios.post(
        "node/admin/userproctoring/image",
        { imageNames: deleteImages.withOutKey },
        getHeaderDetailsForAxios()
      );

      if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
        let result = await deleteS3Objects(deleteImages);

        if (result === "Success") {
          Alert.setShowNotify({
            show: true,
            title: "Success",
            msg: res.data.msg,
          });

          let details = JSON.parse(JSON.stringify(Alert.profile));

          details.proctoringdatas = details.proctoringdatas?.filter(
            (detail) => !deleteImages.withOutKey.includes(detail.imagename)
          );

          Alert.setProfile(details);
          setDeleteImages({ withKey: [], withOutKey: [] });
        }
      } else if (
        res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR
      ) {
        Alert.setShowNotify({
          show: true,
          title: "Error",
          msg: res.data.msg,
        });
      } else {
        Alert.setShowNotify({
          show: true,
          title: "Error",
          msg: "Something went wrong. Please try again later.",
        });
      }
    } catch (err) {
      getCatchBlockDetails(err);
    } finally {
      Alert.setIsLoaded(false);
    }
  };

  const adminSubmitTest = () => {
    Alert.setIsLoaded(true);

    let url = getAPIurl({
      method: "post",
      language: Alert.codeDetails.langname.toLowerCase(),
      api: "submitcode",
    });

    axios
      .post(
        url,
        JSON.stringify({
          learnerid: Alert.learnerId,
          qnid: Alert.codeDetails.qnid,
          langid: Alert.codeDetails.langid,
          testid: Alert.codeDetails.testid,
          timeleft: Alert.codeDetails.timeleft,
          lpconfigid: Alert.codeDetails.lpconfigid,
          testConfig: Alert.codeDetails.testconfigid,
          timedout: Alert.isAdminSubmitTimeout.current,
          proctored: Alert.codeDetails.proctored === 1,
        }),
        {
          timeout: 30000,
          mode: "no-cors",
          headers: {
            "Content-type": "application/json",
            Authorization: localStorage.getItem("tokenKey"),
          },
        }
      )
      .then((res) => {
        Alert.setShowNotify({
          show: true,
          title: "Info",
          msg: "Submission successfull!",
        });
      })
      .catch((error) => {
        if (
          error.message.includes("504") ||
          error.message === "timeout of 30000ms exceeded"
        ) {
          Alert.setShowNotify({
            show: true,
            title: "Error",
            msg: "Something went wrong… program is taking too long to finish. Please, try again",
          });
        } else {
          getCatchBlockDetails(error);
        }
      })
      .finally(() => Alert.setIsLoaded(false));
  };

  return {
    showAll,
    setShowAll,
    showProfile,
    deleteImages,
    handleReject,
    handleApprove,
    showProctoring,
    getProctorData,
    handleDeleteImg,
    setDeleteImages,
    adminSubmitTest,
    showSubmitButton,
    handleProfileShow,
    handleSelectImage,
    setShowProctoring,
    handleProfileClose,
    handleShowProctoring,
    handleProctoringClosePopup,
  };
}
