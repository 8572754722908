import {
  S3Client,
  PutObjectCommand,
  UploadPartCommand,
  CreateMultipartUploadCommand,
  CompleteMultipartUploadCommand,
  DeleteObjectsCommand,
  ListObjectsV2Command,
  GetObjectCommand,
} from "@aws-sdk/client-s3";

const s3Client = new S3Client({
  region: process.env.REACT_APP_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  },
});

const useUploadLargeFileToS3 = () => {
  function getContentType(extension) {
    const mimeTypes = {
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      png: "image/png",
      gif: "image/gif",
      html: "text/html",
      css: "text/css",
      js: "application/javascript",
      json: "application/json",
      zip: "application/zip",
      txt: "text/plain",
      pdf: "application/pdf",
      mp4: "video/mp4",
      jfif: "image/jpeg",
    };
    return mimeTypes[extension] || "application/octet-stream";
  }

  const uploadLargeFileToS3 = async (file, filePath, extension) => {
    try {
      // Step 1: Create a multipart upload

      const createParams = {
        Key: filePath,
        ContentType: getContentType(extension.toLowerCase()),
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      };

      const createResponse = await s3Client.send(
        new CreateMultipartUploadCommand(createParams)
      );

      const uploadId = createResponse.UploadId;
      const partSize = 5 * 1024 * 1024; // 5MB (adjust the part size as needed)

      // Step 2: Upload file parts

      const parts = [];

      let partNumber = 1;
      let start = 0;
      let end = partSize;

      while (start < file.size) {
        const part = file.slice(start, end);
        const uploadPartParams = {
          Body: part,
          Key: filePath,
          UploadId: uploadId,
          PartNumber: partNumber,
          ContentType: getContentType(extension.toLowerCase()),
          Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        };
        const uploadPartResponse = await s3Client.send(
          new UploadPartCommand(uploadPartParams)
        );

        parts.push({
          PartNumber: partNumber,
          ETag: uploadPartResponse.ETag,
        });

        partNumber++;
        start = end;
        end = start + partSize;
      }

      // Step 3: Complete the multipart upload

      const completeParams = {
        Key: filePath,
        UploadId: uploadId,
        ContentType: getContentType(extension.toLowerCase()),
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        MultipartUpload: {
          Parts: parts,
        },
      };
      await s3Client.send(new CompleteMultipartUploadCommand(completeParams));

      return "Success";
    } catch (err) {
      console.error(err);

      return err;
    }
  };

  const uploadFileToS3 = async (
    files,
    filePath,
    extension,
    type,
    extraParams = {}
  ) => {
    try {
      const command = new PutObjectCommand({
        Key: filePath,
        Body: !type ? JSON.stringify(files) : files,
        ContentType: getContentType(extension.toLowerCase()),
        ...extraParams,
        Bucket: !type
          ? process.env.REACT_APP_S3_BUCKET_NAME
          : process.env.REACT_APP_S3_WEBSITE_HOST_BUCKET_NAME,
      });

      await s3Client.send(command);

      return "Success";
    } catch (err) {
      return err;
    }
  };

  const deleteS3Objects = async (objects, type) => {
    let newObjects = JSON.parse(JSON.stringify(objects));

    const command = new DeleteObjectsCommand({
      Bucket: !type
        ? process.env.REACT_APP_S3_BUCKET_NAME
        : process.env.REACT_APP_S3_WEBSITE_HOST_BUCKET_NAME,
      Delete: {
        Objects: !type ? newObjects.withKey : newObjects,
      },
    });

    await s3Client.send(command);

    return "Success";
  };

  const getS3Object = async (key, bucket) => {
    try {
      const params = {
        Key: key,
        Bucket: bucket,
      };

      const data = await s3Client.send(new GetObjectCommand(params));

      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getS3ObjectByFolder = async (key) => {
    const params = {
      Bucket: process.env.REACT_APP_S3_WEBSITE_HOST_BUCKET_NAME,
      Prefix: key,
    };

    try {
      const listCommand = new ListObjectsV2Command(params);
      const { Contents } = await s3Client.send(listCommand);
      if (!Contents) return {};
      const filePromises = Contents.map(async (file) => {
        const getParams = {
          Bucket: process.env.REACT_APP_S3_WEBSITE_HOST_BUCKET_NAME,
          Key: file.Key,
          IfMatch:file.ETag
        };

        const getCommand = new GetObjectCommand(getParams);
        const { Body, ContentType } = await s3Client.send(getCommand);

        let content;
        if (
          ContentType.startsWith("text") ||
          ContentType === "application/json"
        ) {
          content = await new Response(Body).text();
        } else if (ContentType.startsWith("image")) {
          const blob = await new Response(Body, {
            headers: {
              "Content-Type": ContentType,
            },
          }).blob();
          content = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
        } else {
          console.error("Unsupported content type:", ContentType);
        }

        return {
          filename: file.Key.replace(key + "/", ""),
          content,
          contentType: ContentType,
        };
      });

      const files = await Promise.all(filePromises);

      const sandpackFiles = files.reduce((acc, file) => {
        if (file.contentType.startsWith("image")) {
          acc[`/${file.filename}`] = {
            code: file.content,
          };
        } else {
          acc[`/${file.filename}`] = {
            code: file.content,
          };
        }
        return acc;
      }, {});
      return sandpackFiles;
    } catch (err) {
      console.error("Error retrieving files from S3:", err);
    }
  };

  return {
    uploadLargeFileToS3,
    uploadFileToS3,
    deleteS3Objects,
    getS3ObjectByFolder,
    getS3Object,
  };
};

export default useUploadLargeFileToS3;
