import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import constants from "../../../constants";
import { FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { Editor } from "@tinymce/tinymce-react";
import IconButton from "@mui/material/IconButton";
import { getDataFromStorage } from "../../../util";
import BackSvgIcon from "../../../Svg/BackSvgIcon";
import GridViewSvg from "../../../Svg/GridViewSvg";
import ListViewSvg from "../../../Svg/ListViewSvg";
import BookmarkSvg from "../../../Svg/BookmarkSvg";
import RightSvgIcon from "../../../Svg/RightSvgIcon";
import React, { useContext, useEffect } from "react";
import Checkbox from "../../CommonComponents/Checkbox";
import BookmarkedSvg from "../../../Svg/BookmarkedSvg";
import AlertContext from "../../../context/AlertContext";
import WobackCloseSvg from "../../../Svg/WobackCloseSvg";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import ButtonMedium from "../../Buttons/ButtonMedium/ButtonMedium";
import StudentTestBackDropCounter from "./StudentTestBackDropCounter";
import { StudentTestPageContext } from "../../../context/StudentTestPageContext";
import StudentTestPageHooks from "../../../Hooks/StudentTestPage/StudentTestPageHooks";
import StudentTestBodyPageHooks from "../../../Hooks/StudentTestPage/StudentTestBodyPageHooks";

const parse = require("html-react-parser");

const PrettoSlider = styled(Slider)({
  color: "#F55533",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#EAECF0",
  },
  "& .MuiSlider-thumb": {
    width: 24,
    height: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    width: 32,
    padding: 0,
    height: 32,
    fontSize: 12,
    lineHeight: 1.2,
    background: "unset",
    backgroundColor: "#101828",
    borderRadius: "50% 50% 50% 0",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

export default function StudentTestBodyPage() {
  const Alert = useContext(AlertContext);
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  const StudentTestPageHks = StudentTestPageHooks();
  const StudentTestBodyPageHks = StudentTestBodyPageHooks();

  useEffect(() => {
    return () => {
      if (StudentTestPageCxt.editorRef.current) {
        StudentTestPageCxt.editorRef.current.destroy();
      }
    };
  }, []);

  /* each qn timelimit */

  useEffect(() => {
    if (!StudentTestPageCxt.showtransition) {
      const handleqnTimeLimit = setInterval(() => {
        if (StudentTestPageCxt.vdoQnTimelimit === 0) {
          StudentTestBodyPageHks.handleSingleVdoQnSubmit();
          clearInterval(handleqnTimeLimit);
        } else {
          StudentTestPageCxt.setVdoQnTimelimit((seconds) => seconds - 1);
        }
      }, 1000);

      return () => {
        clearInterval(handleqnTimeLimit);
      };
    }
  }, [StudentTestPageCxt.vdoQnTimelimit, StudentTestPageCxt.showtransition]);

  /* dynamic font change */

  useEffect(() => {
    if (StudentTestPageCxt.showtransition) {
      const element = StudentTestPageCxt.textRef.current;
      const container = element?.parentElement;
      const containerWidth = container?.clientWidth;
      const minFontSize = 18;
      const maxFontSize = 50;
      const step = 1.5;

      if (element && containerWidth) {
        let currentFontSize = maxFontSize;
        element.style.fontSize = `${currentFontSize}px`;

        // Reduce font size until the text fits within the container

        while (
          element.scrollWidth > containerWidth &&
          currentFontSize > minFontSize
        ) {
          currentFontSize -= step;
          element.style.fontSize = `${currentFontSize}px`;
        }

        let height = element.scrollHeight;

        while (height >= 500 && currentFontSize > minFontSize) {
          currentFontSize -= step;
          height -= 30;
          element.style.fontSize = `${currentFontSize}px`;
        }

        // Ensure the font size is not less than the minimum font size
        if (currentFontSize < minFontSize) {
          currentFontSize = minFontSize;
        }
      }
    }
  }, [StudentTestPageCxt.showtransition]);

  return (
    <>
      <div className="component-one">
        {StudentTestPageCxt.showtransition &&
          StudentTestPageCxt.showTimer &&
          ((!Alert.isAdmin &&
            Alert.mcqAndDesTestAndLpId.status ===
              constants.PROGRESS_STATUS.IN_PROGRESS) ||
            (!Alert.isAdmin &&
              Alert.mcqAndDesTestAndLpId.status ===
                constants.PROGRESS_STATUS.UNLOCKED) ||
            (!Alert.isAdmin &&
              Alert.mcqAndDesTestAndLpId.status ===
                constants.PROGRESS_STATUS.QN_CONTINUE) ||
            (!Alert.isAdmin &&
              Alert.mcqAndDesTestAndLpId.status ===
                constants.PROGRESS_STATUS.REATTEMPT)) &&
          Alert.comingObj.videoqn === 1 && (
            <StudentTestBackDropCounter
              setShowTimer={StudentTestPageCxt.setShowTimer}
              setshowtransition={StudentTestPageCxt.setshowtransition}
            />
          )}
      </div>
      <div className="commontest-mainpage-div">
        {Alert.mcqAndDesTestAndLpId.status ===
          constants.PROGRESS_STATUS.EVALUATED ||
        Alert.mcqAndDesTestAndLpId.status ===
          constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
        Alert.mcqAndDesTestAndLpId.status ===
          constants.PROGRESS_STATUS.PARTIAL_EVALUATED ||
        (Alert.isAdmin &&
          Alert.mcqAndDesTestAndLpId.status ===
            constants.PROGRESS_STATUS.IN_PROGRESS) ||
        (Alert.isAdmin &&
          Alert.mcqAndDesTestAndLpId.status ===
            constants.PROGRESS_STATUS.QN_CONTINUE) ||
        (Alert.isAdmin &&
          Alert.mcqAndDesTestAndLpId.status ===
            constants.PROGRESS_STATUS.REATTEMPT) ? (
          <div className="commontest-mainpage-header">
            <div className="commontest-mainpage-header-change-section">
              <div className="commontest-mainpage-header-back-btn-dropdown">
                <div>
                  <BackSvgIcon
                    onClick={StudentTestBodyPageHks.backToHomePage}
                  />
                </div>
                <FormControl className="header-change-section-form">
                  <Select
                    labelId="learnerSelectLabel"
                    value={StudentTestPageCxt.selSec}
                    className="header-change-section-select"
                    onChange={StudentTestBodyPageHks.handleSecChange}
                  >
                    <MenuItem value={"all"} key={"selectall"}>
                      {"All"}
                    </MenuItem>

                    {Alert.hasOwnProperty("comingObj") &&
                      Alert.comingObj.hasOwnProperty("sections") &&
                      Alert.comingObj.sections.map((sec, ind) => (
                        <MenuItem
                          className="header-change-section-menuitem"
                          value={ind}
                          key={sec.sectionname + "" + ind}
                        >
                          {sec.sectionname}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            {(Alert.isAdmin ||
              Alert.isTrainer ||
              (Alert.isLearner &&
                StudentTestPageCxt.viewTest ===
                  constants.DISPLAY_TEST_RESULT.ALL) ||
              Alert.backupTestData.testmode === constants.MODE.PRACTICE_MODE) &&
              (Alert.backupTestData.sections[0].questions[0]?.libtypeid ===
                constants.libTypeIds.SAQ ||
                Alert.backupTestData.sections[0].questions[0]?.libtypeid ===
                  constants.libTypeIds.LAQ ||
                Alert.backupTestData.sections[0].questions[0]?.libtypeid ===
                  constants.libTypeIds.MCQ_SINGLE ||
                Alert.backupTestData.sections[0].questions[0]?.libtypeid ===
                  constants.libTypeIds.MCQ_MULTI) && (
                <>
                  <div className="Show-answer-div">
                    <Checkbox
                      text={"Show explanation"}
                      checked={StudentTestPageCxt.showSaqLaqAns}
                      onChange={StudentTestBodyPageHks.showAnswer}
                    />
                  </div>
                </>
              )}

            {(Alert.isAdmin ||
              Alert.isTrainer ||
              Alert.backupTestData.testmode === constants.MODE.PRACTICE_MODE) &&
              Alert.comingObj.videoqn === 1 && (
                <div className="Show-answer-div">
                  <Checkbox
                    text={"Show Text"}
                    checked={StudentTestPageCxt.showTranscript}
                    onChange={StudentTestBodyPageHks.showTranscriptFunc}
                  />
                </div>
              )}
          </div>
        ) : (
          <div className="commontest-mainpage-header">
            <div className="commontest-mainpage-header-section">
              <div className="commontest-mainpage-header-section-title">
                Section:
              </div>
              <div className="commontest-mainpage-header-section-name">
                {Alert.comingObj.videoqn !== 1
                  ? Alert.secObj?.sectionname
                  : StudentTestBodyPageHks.getSectionName(
                      Alert.videoQn.current?.filter((data) => data?.selected)[0]
                        ?.id
                    )}
              </div>
              {Alert.comingObj.videoqn === 1 && (
                <>
                  <div className="commontest-mainpage-header-section-title">
                    Question No:
                  </div>
                  <div className="commontest-mainpage-header-section-name">
                    {StudentTestBodyPageHks.getQuestionNo(
                      Alert.videoQn.current?.filter((data) => data?.selected)[0]
                        ?.id
                    )}
                  </div>
                </>
              )}
            </div>
            {Alert.comingObj.videoqn !== 1 &&
              Alert.comingObj.listview === 1 && (
                <div className="commontest-mainpage-header-test-view-mode">
                  <div className="commontest-mainpage-header-test-view-mode-gridbtn">
                    <IconButton
                      onClick={() =>
                        StudentTestBodyPageHks.changeQuestionVisible("single")
                      }
                    >
                      <GridViewSvg />
                    </IconButton>
                  </div>
                  <div className="commontest-mainpage-header-test-view-mode-listbtn">
                    <IconButton
                      onClick={() =>
                        StudentTestBodyPageHks.changeQuestionVisible("multiple")
                      }
                    >
                      <ListViewSvg />
                    </IconButton>
                  </div>
                </div>
              )}
            {Alert.comingObj.videoqn === 1 && (
              <div className="video-qn-timer-div">
                <div className="video-qn-timer-label">Time Left:</div>
                <div className="video-qn-timer">
                  <span>
                    {Math.floor(
                      (StudentTestPageCxt.vdoQnTimelimit % 3600) / 60
                    ).toString().length === 1
                      ? "0" +
                        Math.floor(
                          (StudentTestPageCxt.vdoQnTimelimit % 3600) / 60
                        )
                      : Math.floor(
                          (StudentTestPageCxt.vdoQnTimelimit % 3600) / 60
                        )}
                  </span>
                  {" : "}
                  <span>
                    {Math.floor(
                      (StudentTestPageCxt.vdoQnTimelimit % 3600) % 60
                    ).toString().length === 1
                      ? "0" +
                        Math.floor(
                          (StudentTestPageCxt.vdoQnTimelimit % 3600) % 60
                        )
                      : Math.floor(
                          (StudentTestPageCxt.vdoQnTimelimit % 3600) % 60
                        )}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
        <div
          className={`commontest-mainpage-body${
            !Alert.isAdmin &&
            Alert.comingObj.videoqn === 1 &&
            Alert.mcqAndDesTestAndLpId.status !==
              constants.PROGRESS_STATUS.EVALUATED &&
            Alert.mcqAndDesTestAndLpId.status !==
              constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
            Alert.mcqAndDesTestAndLpId.status !==
              constants.PROGRESS_STATUS.PARTIAL_EVALUATED
              ? "-videoqn"
              : ""
          }`}
        >
          {Alert.mcqAndDesTestAndLpId.status !==
            constants.PROGRESS_STATUS.EVALUATED &&
          Alert.questionContainerSingle ? (
            <div className="commontest-mainpage-single-question-div">
              <div className="commontest-mainpage-single-question-box">
                <div className="commontest-mainpage-single-question-header-div">
                  <div className="commontest-mainpage-single-question-title-div">
                    {Object.keys(StudentTestPageCxt.curObj)?.length > 0 &&
                      StudentTestPageCxt.curObj.hasOwnProperty("review") &&
                      StudentTestPageCxt.curObj.review === -1 && (
                        <IconButton
                          className="commontest-mainpage-single-question-title-reviewlater"
                          onClick={() =>
                            StudentTestBodyPageHks.reviewLater(
                              "single",
                              "noreview",
                              Alert.queObj
                            )
                          }
                        >
                          <BookmarkSvg />
                        </IconButton>
                      )}
                    {Object.keys(StudentTestPageCxt.curObj)?.length > 0 &&
                      StudentTestPageCxt.curObj.hasOwnProperty("review") &&
                      StudentTestPageCxt.curObj.review === 0 && (
                        <IconButton
                          className="commontest-mainpage-single-question-title-reviewlater"
                          onClick={() =>
                            StudentTestBodyPageHks.reviewLater(
                              "single",
                              "review",
                              Alert.queObj
                            )
                          }
                        >
                          <BookmarkedSvg />
                        </IconButton>
                      )}

                    {(Alert.comingObj.videoqn !== 1 || Alert.isAdmin) && (
                      <div
                        className="commontest-mainpage-single-question-title-qnno"
                        ref={(el) =>
                          (StudentTestPageCxt.questionRefs.current[
                            Alert.queInd + 1
                          ] = el)
                        }
                      >
                        {Alert.comingObj.videoqn === 1
                          ? StudentTestPageCxt.videoQnNo.current
                          : Alert.queInd + 1}
                        .
                      </div>
                    )}
                    <div
                      id="dynamicFont"
                      ref={StudentTestPageCxt.textRef}
                      className="commontest-mainpage-single-question-title-qnname"
                    >
                      {Alert.comingObj.videoqn === 1
                        ? Alert.videoQn.current?.filter(
                            (data) => data?.selected
                          )[0]?.name
                        : Alert.queObj?.name}
                    </div>

                    {(Alert.comingObj.videoqn !== 1 || Alert.isAdmin) && (
                      <div className="commontest-mainpage-single-question-title-mark">
                        {Alert.comingObj.videoqn === 1
                          ? "(" +
                            Alert.videoQn.current?.filter(
                              (data) => data?.selected
                            )[0]?.targetscore +
                            " Marks)"
                          : "(" + Alert.queObj?.targetscore + " Marks)"}
                      </div>
                    )}
                  </div>
                </div>
                <div className="commontest-mainpage-single-question-detail-box">
                  {/* if desc is there to show */}
                  {((Alert.comingObj.videoqn !== 1 &&
                    Alert.queObj?.hasOwnProperty("description") &&
                    Alert.queObj?.description != null &&
                    Alert.queObj?.description.length > 0) ||
                    (Alert.comingObj.videoqn === 1 &&
                      Alert.videoQn.current
                        ?.filter((data) => data?.selected)[0]
                        ?.hasOwnProperty("description") &&
                      Alert.videoQn.current?.filter((data) => data?.selected)[0]
                        ?.description !== null &&
                      Alert.videoQn.current?.filter((data) => data?.selected)[0]
                        ?.description.length > 0)) && (
                    <div className="singleQuestionContainerDescription">
                      <br></br>
                      {Alert.comingObj.videoqn === 1
                        ? parse(
                            Alert.videoQn.current?.filter(
                              (data) => data?.selected
                            )[0]?.description
                          )
                        : parse(Alert.queObj?.description)}
                    </div>
                  )}
                  {/* if img is there to show */}
                  {(Alert.comingObj.videoqn !== 1 &&
                    Alert.queObj?.links.length > 0) ||
                  (Alert.comingObj.videoqn === 1 &&
                    Alert.videoQn.current?.filter((data) => data?.selected))[0]
                    ?.links.length > 0 ? (
                    <div>
                      {Alert.comingObj.videoqn === 1
                        ? Alert.videoQn.current
                            ?.filter((data) => data?.selected)[0]
                            ?.links.map((link, index) => (
                              <span
                                className="questionLinks"
                                key={link + "" + Alert.secObj.sectionid + index}
                              >
                                <img
                                  width="400"
                                  height="300"
                                  alt="userImage"
                                  key={link + "img"}
                                  src={
                                    process.env.REACT_APP_DEPLOYMENT !== "AWS"
                                      ? process.env.REACT_APP_NODE_API +
                                        link.link
                                      : link.link
                                  }
                                />
                              </span>
                            ))
                        : Alert.queObj?.links.map((link, index) => (
                            <span
                              className="questionLinks"
                              key={link + "" + Alert.secObj.sectionid + index}
                            >
                              <img
                                width="400"
                                height="300"
                                alt="userImage"
                                key={link + "img"}
                                src={
                                  process.env.REACT_APP_DEPLOYMENT !== "AWS"
                                    ? process.env.REACT_APP_NODE_API + link.link
                                    : link.link
                                }
                              />
                            </span>
                          ))}
                    </div>
                  ) : null}

                  {Alert.comingObj.videoqn !== 1 ? (
                    Alert.queObj?.libtypeid === constants.libTypeIds.SAQ ||
                    Alert.queObj?.libtypeid === constants.libTypeIds.LAQ ? (
                      <div
                        className="text-area-AnswerDiv"
                        key={"textareadiv" + Alert.queObj.id}
                      >
                        <div
                          className="desInputWrapper"
                          data-required={
                            Alert.comingObj.videoqn !== 1
                              ? Alert.queObj?.libtypeid ===
                                constants.libTypeIds.SAQ
                                ? 1024 -
                                  (StudentTestBodyPageHks.answers[Alert.queObj.id] == null
                                    ? 0
                                    : StudentTestBodyPageHks.answers[Alert.queObj.id].length) +
                                  " characters left"
                                : 10000 -
                                  (StudentTestBodyPageHks.answers[Alert.queObj.id] == null
                                    ? 0
                                    : StudentTestBodyPageHks.answers[Alert.queObj.id].length) +
                                  " characters left"
                              : ""
                          }
                        >
                          {StudentTestPageCxt.errorMessage &&
                            Alert.queObj?.id ===
                              StudentTestPageCxt.questionIdOffError && (
                              <label style={{ color: "red" }}>
                                only numbers and decimals are allowed
                              </label>
                            )}

                          <TextareaAutosize
                            minRows={7}
                            placeholder=""
                            id={"inputs" + 1}
                            key={Alert.queObj?.id}
                            className="textareaAnswer"
                            onCut={StudentTestPageHks.handleCPC}
                            onCopy={StudentTestPageHks.handleCPC}
                            onPaste={StudentTestPageHks.handleCPC}
                            input={StudentTestBodyPageHks.answers[Alert.queObj?.id]}
                            value={StudentTestBodyPageHks.answers[Alert.queObj?.id]}
                            oncontextmenu={StudentTestPageHks.handleCPC}
                            name={Alert.queObj?.id + "" + Alert.secObj.sectionid}
                            onChange={(e) => {
                              StudentTestBodyPageHks.collectAnswers(
                                Alert.queObj?.id,
                                e.target.value,
                                Alert.queObj?.libtypeid,
                                Alert.secInd,
                                Alert.queInd,
                                Alert.queObj?.datatype
                              );
                            }}
                            style={{
                              width: "100%",
                              resize: "none",
                              // minWidth: "57vW",
                              borderRadius: "5px",
                              marginBottom: "10px",
                              border:
                                StudentTestPageCxt.errorMessage &&
                                Alert.queObj?.id ===
                                  StudentTestPageCxt.questionIdOffError
                                  ? "1px solid red"
                                  : StudentTestBodyPageHks.answers[Alert.queObj?.id] == null ||
                                    StudentTestBodyPageHks.answers[Alert.queObj?.id].length === 0
                                  ? "1px solid rgba(55, 55, 57, 0.2)"
                                  : "1px solid #D0D5DD",
                            }}
                            maxLength={
                              Alert.queObj?.libtypeid ===
                              constants.libTypeIds.SAQ
                                ? 1024
                                : 10000
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      Alert.optObj?.map((option, index) => (
                        <>
                          <div
                            key={option.optionid + "" + index}
                            className={
                              Alert.isAdmin
                                ? "singleViewQuestionBoxDetailsOptionsDivEachOptionAdmin"
                                : "commontest-mainpage-single-question-options"
                            }
                            onClick={() => {
                              StudentTestBodyPageHks.collectAnswers(
                                Alert.queObj?.id,
                                option.optionid + "",
                                Alert.queObj?.libtypeid
                              );
                            }}
                            style={{
                              borderColor: StudentTestBodyPageHks.answers[
                                Alert.queObj?.id
                              ]?.includes(option.optionid + "")
                                ? Alert.mcqAndDesTestAndLpId.status !==
                                  constants.PROGRESS_STATUS.EVALUATED
                                  ? "#F96343"
                                  : "green"
                                : "",
                              border: StudentTestBodyPageHks.answers[Alert.queObj?.id]?.includes(
                                option.optionid + ""
                              )
                                ? Alert.mcqAndDesTestAndLpId.status !==
                                  constants.PROGRESS_STATUS.EVALUATED
                                  ? "2px solid #F96343"
                                  : "2px solid green"
                                : "",
                            }}
                          >
                            <div
                              className={
                                Alert.isAdmin
                                  ? "singleViewQuestionBoxDetailsOptionsDivEachOptionAdmin"
                                  : "commontest-mainpage-single-question-options-input-label-div"
                              }
                            >
                              <input
                                className="commontest-mainpage-single-question-options-input"
                                id={option.optionid}
                                value={option.optionid}
                                name={Alert.queObj?.id + "" + Alert.secInd}
                                key={
                                  option.optionid +
                                  "" +
                                  Alert.secInd +
                                  Alert.queInd
                                }
                                type={
                                  Alert.queObj?.libtypeid ===
                                  constants.libTypeIds.MCQ_SINGLE
                                    ? "radio"
                                    : "checkbox"
                                }
                                onChange={() => {
                                  StudentTestBodyPageHks.collectAnswers(
                                    Alert.queObj?.id,
                                    option.optionid + "",
                                    Alert.queObj?.libtypeid
                                  );
                                }}
                                checked={
                                  StudentTestBodyPageHks.answers[Alert.queObj?.id]?.includes(
                                    option.optionid + ""
                                  )
                                    ? true
                                    : false
                                }
                                style={{
                                  color: StudentTestBodyPageHks.answers[
                                    Alert.queObj?.id
                                  ]?.includes(option.optionid + "")
                                    ? "#f55533"
                                    : "#D0D5DD",
                                }}
                              />
                              <label
                                className="commontest-mainpage-single-question-option-label"
                                style={{
                                  color: StudentTestBodyPageHks.answers[
                                    Alert.queObj?.id
                                  ]?.includes(option.optionid + "")
                                    ? "#f55533"
                                    : "#101828",
                                }}
                              >
                                {option.optiontext}
                              </label>
                            </div>
                          </div>
                        </>
                      ))
                    )
                  ) : null}
                </div>
              </div>

              <div className="commontest-mainpage-single-question-button-group">
                {Alert.comingObj.videoqn !== 1 &&
                  Alert.questionContainerSingle && (
                    <div
                      className={
                        Alert.prevButton && Alert.nextButton
                          ? "commontest-mainpage-single-question-button-group-div"
                          : "commontest-mainpage-single-question-button-group-div"
                      }
                    >
                      <div>
                        <ButtonMedium
                          btnText2="Previous"
                          secondaryBtn={Alert.prevButton}
                          onHideSecondary={() =>
                            StudentTestBodyPageHks.traversePrevious("previous")
                          }
                        />{" "}
                      </div>
                      <div>
                        <ButtonMedium
                          btnText="Next"
                          primaryBtn={Alert.nextButton}
                          onHide={() =>
                            StudentTestBodyPageHks.traverseNext("next")
                          }
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          ) : (
            <div className={"commontest-mainpage-multiple-question-options"}>
              <div className="commontest-mainpage-multiple-question-container">
                {Alert.hasOwnProperty("comingObj") &&
                  Alert.comingObj.hasOwnProperty("sections") &&
                  StudentTestPageCxt.sectionObj !== null &&
                  typeof StudentTestPageCxt.sectionObj !== "undefined" &&
                  Object.keys(StudentTestPageCxt.sectionObj).length > 0 &&
                  StudentTestPageCxt.sectionObj.hasOwnProperty("questions") && (
                    <>
                      {
                        <div className="commontest-mainpage-multiple-question-container-loop">
                          {StudentTestPageCxt.fSections.map((fsec, fsecind) => (
                            <div className="commontest-mainpage-multiple-question-container-block">
                              {(Alert.mcqAndDesTestAndLpId.status ===
                                constants.PROGRESS_STATUS.EVALUATED ||
                                Alert.mcqAndDesTestAndLpId.status ===
                                  constants.PROGRESS_STATUS.IN_PROGRESS ||
                                Alert.mcqAndDesTestAndLpId.status ===
                                  constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
                                Alert.mcqAndDesTestAndLpId.status ===
                                  constants.PROGRESS_STATUS.PARTIAL_EVALUATED ||
                                Alert.mcqAndDesTestAndLpId.status ===
                                  constants.PROGRESS_STATUS.QN_CONTINUE) &&
                              StudentTestPageCxt.selSec === "all" &&
                              fsec.questions.length > 0 ? (
                                <div className="testContainerChildLeftHeaderSectionTitle2">
                                  <span className="testContainerChildLeftHeaderSectionTitleLabel">
                                    Section :&nbsp;{" "}
                                  </span>{" "}
                                  <span className="testContainerChildLeftHeaderSectionTitleName">
                                    {fsec.sectionname}
                                  </span>
                                  <span className="singleViewQuestionBoxTitleMarks">
                                    {" ( " +
                                      StudentTestPageHks.getSectionTotal(fsec) +
                                      " Marks )"}
                                  </span>
                                </div>
                              ) : null}
                              {fsec.questions.map((questionobj, queindex) => (
                                <>
                                  <div
                                    className="commontest-mainpage-multiple-question-box"
                                    id={
                                      "que" +
                                      (Alert.secInd === "all"
                                        ? 0
                                        : Alert.secInd) +
                                      queindex
                                    }
                                  >
                                    <>
                                      <div class="commontest-mainpage-multiple-question-box-title">
                                        {questionobj.review === -1 && (
                                          <IconButton
                                            className="commontest-mainpage-multiple-question-title-reviewlater"
                                            onClick={() =>
                                              StudentTestBodyPageHks.reviewLater(
                                                "multi",
                                                "noreview",
                                                queindex
                                              )
                                            }
                                          >
                                            <BookmarkSvg />
                                          </IconButton>
                                        )}
                                        {questionobj.review === 0 && (
                                          <IconButton
                                            className="commontest-mainpage-multiple-question-title-reviewlater"
                                            onClick={() =>
                                              StudentTestBodyPageHks.reviewLater(
                                                "multi",
                                                "review",
                                                queindex
                                              )
                                            }
                                          >
                                            <BookmarkedSvg />
                                          </IconButton>
                                        )}

                                        <div className="commontest-mainpage-single-question-title-qnno">
                                          {queindex + 1}.
                                        </div>
                                        <div className="commontest-mainpage-single-question-title-qnname">
                                          {questionobj.name}
                                        </div>

                                        <div className="commontest-mainpage-single-question-title-mark">
                                          {getDataFromStorage("role") ===
                                            "learner" &&
                                            "(" +
                                              (Alert.isLearner &&
                                              StudentTestPageCxt.viewTest ===
                                                constants.DISPLAY_TEST_RESULT
                                                  .ALL &&
                                              Alert.testMode ===
                                                constants.MODE.TEST_MODE &&
                                              Alert.mcqAndDesTestAndLpId
                                                .status ===
                                                constants.PROGRESS_STATUS
                                                  .EVALUATED
                                                ? "" +
                                                  questionobj.actualscore +
                                                  "/"
                                                : "") +
                                              questionobj.targetscore +
                                              " Marks)"}
                                        </div>
                                      </div>
                                      <div className="commontest-mainpage-single-question-option-detail-box">
                                        {questionobj.hasOwnProperty(
                                          "description"
                                        ) &&
                                          questionobj.description != null &&
                                          questionobj.description.length >
                                            0 && (
                                            <div className="multiQuestionContainerDescription">
                                              <br />
                                              {parse(questionobj.description)}
                                            </div>
                                          )}

                                        {questionobj.links.length > 0 ? (
                                          <div className="singleViewQuestionBoxTitleQuestionLinks">
                                            {questionobj.links.map((link) => (
                                              <span className="questionLinks">
                                                <img
                                                  width="400"
                                                  height="400"
                                                  alt="userImage"
                                                  key={
                                                    link +
                                                    "" +
                                                    StudentTestPageCxt.secIndex
                                                  }
                                                  src={
                                                    process.env
                                                      .REACT_APP_DEPLOYMENT !==
                                                    "AWS"
                                                      ? process.env
                                                          .REACT_APP_NODE_API +
                                                        link.link
                                                      : link.link
                                                  }
                                                />
                                              </span>
                                            ))}
                                          </div>
                                        ) : null}
                                        <div className="commontest-mainpage-single-question-option-details-div">
                                          {questionobj.libtypeid ===
                                            constants.libTypeIds.SAQ ||
                                          questionobj.libtypeid ===
                                            constants.libTypeIds.LAQ ? (
                                            <div
                                              className="text-area-AnswerDiv"
                                              key={
                                                questionobj.libtypeid +
                                                questionobj.id
                                              }
                                            >
                                              <div
                                                className="desInputWrapper"
                                                data-required={
                                                  Alert.comingObj.videoqn !== 1
                                                    ? Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .UNLOCKED ||
                                                      Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .REATTEMPT ||
                                                      Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .QN_CONTINUE
                                                      ? questionobj.libtypeid ===
                                                        constants.libTypeIds.SAQ
                                                        ? 1024 -
                                                          (Alert.isAdmin
                                                            ? questionobj.answer !=
                                                              null
                                                              ? questionobj
                                                                  .answer.length
                                                              : 0
                                                            : StudentTestBodyPageHks.answers[
                                                                questionobj.id
                                                              ]?.length) +
                                                          " characters left"
                                                        : 10000 -
                                                          (Alert.isAdmin
                                                            ? questionobj.answer !=
                                                              null
                                                              ? questionobj
                                                                  .answer.length
                                                              : 0
                                                            : StudentTestBodyPageHks.answers[
                                                                questionobj.id
                                                              ]?.length) +
                                                          " characters left"
                                                      : questionobj.libtypeid ===
                                                        constants.libTypeIds.SAQ
                                                      ? 1024 -
                                                        (Alert.isAdmin
                                                          ? questionobj.answer !=
                                                            null
                                                            ? questionobj.answer
                                                                .length
                                                            : 0
                                                          : questionobj.answer
                                                              ?.length) +
                                                        " characters left"
                                                      : 10000 -
                                                        (Alert.isAdmin
                                                          ? questionobj.answer !=
                                                            null
                                                            ? questionobj.answer
                                                                .length
                                                            : 0
                                                          : questionobj.answer
                                                              ?.length) +
                                                        " characters left"
                                                    : ""
                                                }
                                              >
                                                {StudentTestPageCxt.errorMessage &&
                                                  questionobj.id ===
                                                    StudentTestPageCxt.questionIdOffError && (
                                                    <label
                                                      style={{
                                                        color: "red",
                                                        display: "flex",
                                                      }}
                                                    >
                                                      only numbers and decimals
                                                      are allowed
                                                    </label>
                                                  )}
                                                {Alert.comingObj.videoqn ===
                                                1 ? (
                                                  questionobj.answer !== "" && (
                                                    <video
                                                      className="videoqn-admin-answer"
                                                      controls
                                                      controlsList="nodownload"
                                                      src={questionobj.answer}
                                                    ></video>
                                                  )
                                                ) : (
                                                  <TextareaAutosize
                                                    minRows={7}
                                                    id={questionobj.id}
                                                    key={questionobj.id}
                                                    className="textareaAnswer"
                                                    onCut={
                                                      StudentTestPageHks.handleCPC
                                                    }
                                                    onCopy={
                                                      StudentTestPageHks.handleCPC
                                                    }
                                                    onPaste={
                                                      StudentTestPageHks.handleCPC
                                                    }
                                                    oncontextmenu={
                                                      StudentTestPageHks.handleCPC
                                                    }
                                                    name={
                                                      questionobj.id +
                                                      "" +
                                                      StudentTestPageCxt
                                                        .sectionObj.sectionid
                                                    }
                                                    value={
                                                      Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .EVALUATED ||
                                                      Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .TO_BE_EVALUATED ||
                                                      Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .PARTIAL_EVALUATED ||
                                                      Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .IN_PROGRESS ||
                                                      (Alert.isAdmin &&
                                                        Alert
                                                          .mcqAndDesTestAndLpId
                                                          .status ===
                                                          constants
                                                            .PROGRESS_STATUS
                                                            .QN_CONTINUE)
                                                        ? questionobj.answer
                                                        : StudentTestBodyPageHks.answers[
                                                            questionobj.id
                                                          ]
                                                    }
                                                    disabled={
                                                      Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .EVALUATED ||
                                                      Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .TO_BE_EVALUATED ||
                                                      Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .PARTIAL_EVALUATED ||
                                                      Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .IN_PROGRESS ||
                                                      (Alert.isAdmin &&
                                                        Alert
                                                          .mcqAndDesTestAndLpId
                                                          .status ===
                                                          constants
                                                            .PROGRESS_STATUS
                                                            .QN_CONTINUE)
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(e) => {
                                                      StudentTestBodyPageHks.collectAnswers(
                                                        questionobj.id,
                                                        e.target.value,
                                                        questionobj.libtypeid,
                                                        StudentTestPageCxt.secIndex,
                                                        queindex,
                                                        questionobj?.datatype
                                                      );
                                                    }}
                                                    style={{
                                                      width: "100%",
                                                      resize: "none",
                                                      // minWidth: "57vW",
                                                      borderRadius: "5px",
                                                      marginBottom: "10px",
                                                      border:
                                                        StudentTestPageCxt.errorMessage &&
                                                        questionobj.id ===
                                                          StudentTestPageCxt.questionIdOffError
                                                          ? "1px solid red"
                                                          : Alert
                                                              .mcqAndDesTestAndLpId
                                                              .status ===
                                                              constants
                                                                .PROGRESS_STATUS
                                                                .UNLOCKED ||
                                                            Alert
                                                              .mcqAndDesTestAndLpId
                                                              .status ===
                                                              constants
                                                                .PROGRESS_STATUS
                                                                .REATTEMPT ||
                                                            Alert
                                                              .mcqAndDesTestAndLpId
                                                              .status ===
                                                              constants
                                                                .PROGRESS_STATUS
                                                                .QN_CONTINUE
                                                          ? StudentTestBodyPageHks.answers[
                                                              questionobj.id
                                                            ]?.length === 0
                                                            ? "1px solid rgba(55, 55, 57, 0.2)"
                                                            : "1px solid #D0D5DD"
                                                          : questionobj.answer
                                                              ?.length === 0
                                                          ? "1px solid rgba(55, 55, 57, 0.2)"
                                                          : "1px solid #D0D5DD",
                                                    }}
                                                    maxLength={
                                                      questionobj.libtypeid ===
                                                      constants.libTypeIds.SAQ
                                                        ? 1024
                                                        : 10000
                                                    }
                                                  />
                                                )}
                                              </div>

                                              {StudentTestPageCxt.showSaqLaqAns && (
                                                <div className="desc-admin-answer-div">
                                                  <div className="desc-admin-answer-label-div">
                                                    Explanation
                                                  </div>
                                                  <textarea
                                                    rows={5}
                                                    disabled={true}
                                                    id={
                                                      questionobj.id +
                                                      "showanswer"
                                                    }
                                                    key={
                                                      questionobj.id +
                                                      "showanswer"
                                                    }
                                                    className="desc-admin-answer"
                                                    name={
                                                      questionobj.id +
                                                      "" +
                                                      StudentTestPageCxt
                                                        .sectionObj.sectionid +
                                                      "showanswer"
                                                    }
                                                    value={questionobj.options
                                                      .map(
                                                        (obj) => obj.optiontext
                                                      )
                                                      .join("/")}
                                                  />
                                                </div>
                                              )}
                                              {StudentTestPageCxt.showTranscript && (
                                                <div className="desc-admin-answer-div">
                                                  <div className="desc-admin-answer-label-div">
                                                    Transcript
                                                  </div>
                                                  <textarea
                                                    rows={5}
                                                    disabled={true}
                                                    id={
                                                      questionobj.id +
                                                      "showanswer"
                                                    }
                                                    key={
                                                      questionobj.id +
                                                      "showanswer"
                                                    }
                                                    className="desc-admin-answer"
                                                    name={
                                                      questionobj.id +
                                                      "" +
                                                      StudentTestPageCxt
                                                        .sectionObj.sectionid +
                                                      "showanswer"
                                                    }
                                                    value={
                                                      questionobj?.transcript
                                                    }
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            questionobj.options.map(
                                              (option, optindex) =>
                                                questionobj.libtypeid ===
                                                constants.libTypeIds
                                                  .MCQ_SINGLE ? (
                                                  <div
                                                    key={
                                                      option.optionid +
                                                      "" +
                                                      optindex +
                                                      StudentTestPageCxt.secIndex +
                                                      questionobj.libtypeid
                                                    }
                                                    className={
                                                      "commontest-mainpage-single-question-option-each-div"
                                                    }
                                                    onClick={
                                                      Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .UNLOCKED ||
                                                      Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .REATTEMPT ||
                                                      Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .QN_CONTINUE
                                                        ? () => {
                                                            StudentTestBodyPageHks.collectAnswers(
                                                              questionobj.id,
                                                              option.optionid +
                                                                "",
                                                              questionobj.libtypeid,
                                                              StudentTestPageCxt.secIndex,
                                                              queindex,
                                                              0
                                                            );
                                                          }
                                                        : null
                                                    }
                                                    style={{
                                                      borderColor:
                                                        Alert
                                                          .mcqAndDesTestAndLpId
                                                          .status !==
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .EVALUATED
                                                          ? StudentTestBodyPageHks.answers[
                                                              questionobj.id
                                                            ]?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                            ? "#F96343"
                                                            : (Alert.isAdmin ||
                                                                Alert.isTrainer) &&
                                                              questionobj.answer?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                            ? "#F96343"
                                                            : ""
                                                          : (Alert.isAdmin ||
                                                              Alert.isTrainer ||
                                                              (Alert.isLearner &&
                                                                StudentTestPageCxt.viewTest ===
                                                                  constants
                                                                    .DISPLAY_TEST_RESULT
                                                                    .ALL)) &&
                                                            option.iscorrectoption ===
                                                              1 &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? "#12B76A"
                                                          : (Alert.isAdmin ||
                                                              Alert.isTrainer ||
                                                              (Alert.isLearner &&
                                                                StudentTestPageCxt.viewTest ===
                                                                  constants
                                                                    .DISPLAY_TEST_RESULT
                                                                    .ALL)) &&
                                                            option.iscorrectoption ===
                                                              0 &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? "#F04438"
                                                          : "",
                                                      border:
                                                        Alert
                                                          .mcqAndDesTestAndLpId
                                                          .status !==
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .EVALUATED
                                                          ? StudentTestBodyPageHks.answers[
                                                              questionobj.id
                                                            ]?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                            ? "2px solid #F96343"
                                                            : (Alert.isAdmin ||
                                                                Alert.isTrainer) &&
                                                              questionobj.answer?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                            ? "2px solid #F96343"
                                                            : ""
                                                          : (Alert.isAdmin ||
                                                              Alert.isTrainer ||
                                                              (Alert.isLearner &&
                                                                StudentTestPageCxt.viewTest ===
                                                                  constants
                                                                    .DISPLAY_TEST_RESULT
                                                                    .ALL)) &&
                                                            option.iscorrectoption ===
                                                              1 &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? "2px solid #12B76A"
                                                          : (Alert.isAdmin ||
                                                              Alert.isTrainer ||
                                                              (Alert.isLearner &&
                                                                StudentTestPageCxt.viewTest ===
                                                                  constants
                                                                    .DISPLAY_TEST_RESULT
                                                                    .ALL)) &&
                                                            option.iscorrectoption ===
                                                              0 &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? "2px solid #F04438"
                                                          : "",
                                                    }}
                                                  >
                                                    <div
                                                      className={
                                                        "commontest-mainpage-multiple-question-options-input-label-div"
                                                      }
                                                    >
                                                      <div
                                                        className={
                                                          "options-input-label-div"
                                                        }
                                                      >
                                                        <input
                                                          type="radio"
                                                          className="commontest-mainpage-multiple-question-options-input"
                                                          key={
                                                            option.optionid +
                                                            "" +
                                                            optindex +
                                                            StudentTestPageCxt.secIndex +
                                                            questionobj.libtypeid
                                                          }
                                                          name={
                                                            questionobj.id +
                                                            "" +
                                                            StudentTestPageCxt
                                                              .sectionObj
                                                              .sectionid
                                                          }
                                                          id={
                                                            option.optionid +
                                                            "" +
                                                            optindex +
                                                            StudentTestPageCxt.secIndex +
                                                            questionobj.libtypeid
                                                          }
                                                          value={
                                                            option.optionid +
                                                            "" +
                                                            optindex +
                                                            StudentTestPageCxt.secIndex +
                                                            questionobj.libtypeid
                                                          }
                                                          onChange={() => {
                                                            StudentTestBodyPageHks.collectAnswers(
                                                              questionobj.id,
                                                              option.optionid +
                                                                "",
                                                              questionobj.libtypeid,
                                                              StudentTestPageCxt.secIndex,
                                                              queindex,
                                                              0
                                                            );
                                                          }}
                                                          checked={
                                                            Alert
                                                              .mcqAndDesTestAndLpId
                                                              .status !==
                                                            constants
                                                              .PROGRESS_STATUS
                                                              .EVALUATED
                                                              ? StudentTestBodyPageHks.answers[
                                                                  questionobj.id
                                                                ]?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                                ? true
                                                                : (Alert.isAdmin ||
                                                                    Alert.isTrainer) &&
                                                                  questionobj.answer?.includes(
                                                                    option.optionid +
                                                                      ""
                                                                  )
                                                                ? true
                                                                : false
                                                              : option.iscorrectoption ===
                                                                  1 &&
                                                                questionobj.answer?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                              ? true
                                                              : option.iscorrectoption ===
                                                                  0 &&
                                                                questionobj.answer?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                              ? true
                                                              : false
                                                          }
                                                          style={{
                                                            accentColor:
                                                              Alert
                                                                .mcqAndDesTestAndLpId
                                                                .status !==
                                                              constants
                                                                .PROGRESS_STATUS
                                                                .EVALUATED
                                                                ? StudentTestBodyPageHks.answers[
                                                                    questionobj
                                                                      .id
                                                                  ]?.includes(
                                                                    option.optionid +
                                                                      ""
                                                                  )
                                                                  ? "#F96343"
                                                                  : (Alert.isAdmin ||
                                                                      Alert.isTrainer) &&
                                                                    questionobj.answer?.includes(
                                                                      option.optionid +
                                                                        ""
                                                                    )
                                                                  ? "#F96343"
                                                                  : ""
                                                                : (Alert.isAdmin ||
                                                                    Alert.isTrainer ||
                                                                    (Alert.isLearner &&
                                                                      StudentTestPageCxt.viewTest ===
                                                                        constants
                                                                          .DISPLAY_TEST_RESULT
                                                                          .ALL)) &&
                                                                  option.iscorrectoption ===
                                                                    1 &&
                                                                  questionobj.answer?.includes(
                                                                    option.optionid +
                                                                      ""
                                                                  )
                                                                ? "green"
                                                                : (Alert.isAdmin ||
                                                                    Alert.isTrainer ||
                                                                    (Alert.isLearner &&
                                                                      StudentTestPageCxt.viewTest ===
                                                                        constants
                                                                          .DISPLAY_TEST_RESULT
                                                                          .ALL)) &&
                                                                  option.iscorrectoption ===
                                                                    0 &&
                                                                  questionobj.answer?.includes(
                                                                    option.optionid +
                                                                      ""
                                                                  )
                                                                ? "#F04438"
                                                                : "#F96343",
                                                          }}
                                                        />
                                                        <label className="commontest-mainpage-multiple-question-options-label">
                                                          {option.optiontext}
                                                        </label>
                                                      </div>
                                                      <div>
                                                        {(Alert.isAdmin ||
                                                          Alert.isTrainer ||
                                                          (Alert.isLearner &&
                                                            StudentTestPageCxt.viewTest ===
                                                              constants
                                                                .DISPLAY_TEST_RESULT
                                                                .ALL)) &&
                                                        option.iscorrectoption ===
                                                          1 ? (
                                                          <RightSvgIcon />
                                                        ) : (Alert.isAdmin ||
                                                            Alert.isTrainer ||
                                                            (Alert.isLearner &&
                                                              StudentTestPageCxt.viewTest ===
                                                                constants
                                                                  .DISPLAY_TEST_RESULT
                                                                  .ALL)) &&
                                                          option.iscorrectoption ===
                                                            0 &&
                                                          questionobj.answer?.includes(
                                                            option.optionid + ""
                                                          ) ? (
                                                          <WobackCloseSvg
                                                            stoke={"#F04438"}
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : questionobj.libtypeid ===
                                                  constants.libTypeIds
                                                    .MCQ_MULTI ? (
                                                  <div
                                                    key={
                                                      option.optionid +
                                                      "" +
                                                      optindex +
                                                      StudentTestPageCxt.secIndex +
                                                      questionobj.libtypeid
                                                    }
                                                    className={
                                                      Alert.isAdmin
                                                        ? "singleViewQuestionBoxDetailsOptionsDivEachOptionAdmin"
                                                        : "commontest-mainpage-single-question-option-each-div"
                                                    }
                                                    onClick={
                                                      Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .UNLOCKED ||
                                                      Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .REATTEMPT ||
                                                      Alert.mcqAndDesTestAndLpId
                                                        .status ===
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .QN_CONTINUE
                                                        ? () => {
                                                            StudentTestBodyPageHks.collectAnswers(
                                                              questionobj.id,
                                                              option.optionid +
                                                                "",
                                                              questionobj.libtypeid,
                                                              StudentTestPageCxt.secIndex,
                                                              queindex,
                                                              0
                                                            );
                                                          }
                                                        : null
                                                    }
                                                    style={{
                                                      borderColor:
                                                        Alert
                                                          .mcqAndDesTestAndLpId
                                                          .status !==
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .EVALUATED
                                                          ? StudentTestBodyPageHks.answers[
                                                              questionobj.id
                                                            ]?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                            ? "#F96343"
                                                            : (Alert.isAdmin ||
                                                                Alert.isTrainer) &&
                                                              questionobj.answer?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                            ? "#F96343"
                                                            : ""
                                                          : (Alert.isAdmin ||
                                                              Alert.isTrainer ||
                                                              (Alert.isLearner &&
                                                                StudentTestPageCxt.viewTest ===
                                                                  constants
                                                                    .DISPLAY_TEST_RESULT
                                                                    .ALL)) &&
                                                            option.iscorrectoption ===
                                                              1 &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? "#12B76A"
                                                          : (Alert.isAdmin ||
                                                              Alert.isTrainer ||
                                                              (Alert.isLearner &&
                                                                StudentTestPageCxt.viewTest ===
                                                                  constants
                                                                    .DISPLAY_TEST_RESULT
                                                                    .ALL)) &&
                                                            option.iscorrectoption ===
                                                              0 &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? "#F04438"
                                                          : "",
                                                      border:
                                                        Alert
                                                          .mcqAndDesTestAndLpId
                                                          .status !==
                                                        constants
                                                          .PROGRESS_STATUS
                                                          .EVALUATED
                                                          ? StudentTestBodyPageHks.answers[
                                                              questionobj.id
                                                            ]?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                            ? "2px solid #F96343"
                                                            : (Alert.isAdmin ||
                                                                Alert.isTrainer) &&
                                                              questionobj.answer?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                            ? "2px solid #F96343"
                                                            : ""
                                                          : (Alert.isAdmin ||
                                                              Alert.isTrainer ||
                                                              (Alert.isLearner &&
                                                                StudentTestPageCxt.viewTest ===
                                                                  constants
                                                                    .DISPLAY_TEST_RESULT
                                                                    .ALL)) &&
                                                            option.iscorrectoption ===
                                                              1 &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? "2px solid #12B76A"
                                                          : (Alert.isAdmin ||
                                                              Alert.isTrainer ||
                                                              (Alert.isLearner &&
                                                                StudentTestPageCxt.viewTest ===
                                                                  constants
                                                                    .DISPLAY_TEST_RESULT
                                                                    .ALL)) &&
                                                            option.iscorrectoption ===
                                                              0 &&
                                                            questionobj.answer?.includes(
                                                              option.optionid +
                                                                ""
                                                            )
                                                          ? "2px solid #F04438"
                                                          : "",
                                                    }}
                                                  >
                                                    <div
                                                      className={
                                                        "commontest-mainpage-multiple-question-options-input-label-div"
                                                      }
                                                    >
                                                      <input
                                                        type="checkbox"
                                                        className="commontest-mainpage-multiple-question-options-input"
                                                        key={
                                                          option.optionid +
                                                          "" +
                                                          optindex +
                                                          StudentTestPageCxt.secIndex +
                                                          questionobj.libtypeid
                                                        }
                                                        name={
                                                          questionobj.id +
                                                          "" +
                                                          StudentTestPageCxt
                                                            .sectionObj
                                                            .sectionid
                                                        }
                                                        id={
                                                          option.optionid +
                                                          "" +
                                                          optindex +
                                                          StudentTestPageCxt.secIndex +
                                                          questionobj.libtypeid
                                                        }
                                                        value={
                                                          option.optionid +
                                                          "" +
                                                          optindex +
                                                          StudentTestPageCxt.secIndex +
                                                          questionobj.libtypeid
                                                        }
                                                        onChange={() => {
                                                          StudentTestBodyPageHks.collectAnswers(
                                                            questionobj.id,
                                                            option.optionid +
                                                              "",
                                                            questionobj.libtypeid,
                                                            StudentTestPageCxt.secIndex,
                                                            queindex,
                                                            0
                                                          );
                                                        }}
                                                        checked={
                                                          Alert
                                                            .mcqAndDesTestAndLpId
                                                            .status !==
                                                          constants
                                                            .PROGRESS_STATUS
                                                            .EVALUATED
                                                            ? StudentTestBodyPageHks.answers[
                                                                questionobj.id
                                                              ]?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                              ? true
                                                              : (Alert.isAdmin ||
                                                                  Alert.isTrainer) &&
                                                                questionobj.answer?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                              ? true
                                                              : false
                                                            : option.iscorrectoption ===
                                                                1 &&
                                                              questionobj.answer?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                            ? true
                                                            : option.iscorrectoption ===
                                                                0 &&
                                                              questionobj.answer?.includes(
                                                                option.optionid +
                                                                  ""
                                                              )
                                                            ? true
                                                            : false
                                                        }
                                                        style={{
                                                          accentColor:
                                                            Alert
                                                              .mcqAndDesTestAndLpId
                                                              .status !==
                                                            constants
                                                              .PROGRESS_STATUS
                                                              .EVALUATED
                                                              ? StudentTestBodyPageHks.answers[
                                                                  questionobj.id
                                                                ]?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                                ? "#F96343"
                                                                : (Alert.isAdmin ||
                                                                    Alert.isTrainer) &&
                                                                  questionobj.answer?.includes(
                                                                    option.optionid +
                                                                      ""
                                                                  )
                                                                ? "#F96343"
                                                                : ""
                                                              : (Alert.isAdmin ||
                                                                  Alert.isTrainer ||
                                                                  (Alert.isLearner &&
                                                                    StudentTestPageCxt.viewTest ===
                                                                      constants
                                                                        .DISPLAY_TEST_RESULT
                                                                        .ALL)) &&
                                                                option.iscorrectoption ===
                                                                  1 &&
                                                                questionobj.answer?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                              ? "green"
                                                              : (Alert.isAdmin ||
                                                                  Alert.isTrainer ||
                                                                  (Alert.isLearner &&
                                                                    StudentTestPageCxt.viewTest ===
                                                                      constants
                                                                        .DISPLAY_TEST_RESULT
                                                                        .ALL)) &&
                                                                option.iscorrectoption ===
                                                                  0 &&
                                                                questionobj.answer?.includes(
                                                                  option.optionid +
                                                                    ""
                                                                )
                                                              ? "#F04438"
                                                              : "#F96343",
                                                        }}
                                                      />
                                                      <label className="commontest-mainpage-multiple-question-options-label">
                                                        {option.optiontext}
                                                      </label>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )
                                            )
                                          )}
                                        </div>
                                      </div>
                                      {Alert.isAdmin &&
                                        (Alert.mcqAndDesTestAndLpId.status ===
                                          constants.PROGRESS_STATUS.EVALUATED ||
                                          Alert.mcqAndDesTestAndLpId.status ===
                                            constants.PROGRESS_STATUS
                                              .TO_BE_EVALUATED ||
                                          Alert.mcqAndDesTestAndLpId.status ===
                                            constants.PROGRESS_STATUS
                                              .PARTIAL_EVALUATED) &&
                                        (Alert.backupTestData.sections[0]
                                          .questions[0]?.libtypeid ===
                                          constants.libTypeIds.SAQ ||
                                          Alert.backupTestData.sections[0]
                                            .questions[0]?.libtypeid ===
                                            constants.libTypeIds.LAQ) && (
                                          <div className="admin-Marks-Input-Div">
                                            <div className="admin-Marks-Input-DivItems">
                                              <div
                                                key={questionobj.id}
                                                className="admin-Marks-Input"
                                              >
                                                <input
                                                  min="0"
                                                  className="saq-Laq-Marks-Entry"
                                                  max={
                                                    questionobj.targetscore + ""
                                                  }
                                                  placeholder={
                                                    questionobj.hasOwnProperty(
                                                      "actualscore"
                                                    )
                                                      ? questionobj.actualscore
                                                      : ""
                                                  }
                                                  value={
                                                    Alert.descScores.hasOwnProperty(
                                                      questionobj.id
                                                    ) &&
                                                    Alert.descScores[
                                                      questionobj.id
                                                    ] != null
                                                      ? Alert.descScores[
                                                          questionobj.id
                                                        ]
                                                      : questionobj.hasOwnProperty(
                                                          "actualscore"
                                                        ) &&
                                                        questionobj.actualscore !=
                                                          null
                                                      ? questionobj.actualscore
                                                      : ""
                                                  }
                                                  style={{
                                                    borderColor:
                                                      StudentTestPageCxt.scoreErrors.hasOwnProperty(
                                                        questionobj.id
                                                      )
                                                        ? "red"
                                                        : "black",
                                                  }}
                                                  onChange={(e) => {
                                                    StudentTestBodyPageHks.collectScores(
                                                      questionobj.id,
                                                      e.target.value,
                                                      questionobj.targetscore,
                                                      e
                                                    );
                                                  }}
                                                  onKeyDown={(e) => {
                                                    StudentTestPageHks.handleScoreKeyDown(
                                                      e
                                                    );
                                                  }}
                                                  onWheel={(e) =>
                                                    e.target.blur()
                                                  }
                                                />

                                                <div className="approveScoreDivTotalMarks">
                                                  /{" "}
                                                  {questionobj.targetscore +
                                                    " Marks"}
                                                </div>
                                              </div>

                                              {Alert.isAdmin && (
                                                <div>
                                                  <Box sx={{ width: 250 }}>
                                                    <PrettoSlider
                                                      valueLabelDisplay="auto"
                                                      aria-label="pretto slider"
                                                      step={0.5}
                                                      value={
                                                        Alert.descScores.hasOwnProperty(
                                                          questionobj.id
                                                        )
                                                          ? Alert.descScores[
                                                              questionobj.id
                                                            ]
                                                          : questionobj.hasOwnProperty(
                                                              "actualscore"
                                                            ) &&
                                                            questionobj.actualscore ==
                                                              null
                                                          ? ""
                                                          : questionobj.actualscore
                                                      }
                                                      onChange={(e) => {
                                                        StudentTestBodyPageHks.collectScores(
                                                          questionobj.id,
                                                          e.target.value,
                                                          questionobj.targetscore,
                                                          e
                                                        );
                                                      }}
                                                      disabled={
                                                        StudentTestPageCxt.giveZeros &&
                                                        (questionobj.answer ===
                                                          "" ||
                                                          questionobj.answer ==
                                                            null)
                                                      }
                                                      max={
                                                        questionobj.targetscore
                                                      }
                                                    />
                                                  </Box>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                    </>
                                  </div>
                                  <div className="commontest-mainpage-single-multiple-question-answer-score-div">
                                    {StudentTestPageCxt.showSaqLaqAns &&
                                      (questionobj.libtypeid ===
                                        constants.libTypeIds.MCQ_SINGLE ||
                                        questionobj.libtypeid ===
                                          constants.libTypeIds.MCQ_MULTI) && (
                                        <div className="mcq-admin-answer-div">
                                          <div className="mcq-admin-answer-label-div">
                                            Explanation
                                          </div>
                                          <Editor
                                            toolbar=""
                                            disabled={true}
                                            menubar={false}
                                            branding={false}
                                            statusbar={false}
                                            className="mcq-admin-answer"
                                            value={
                                              questionobj?.answerexplanation
                                            }
                                            tinymceScriptSrc="/tinymce/tinymce.min.js"
                                            onInit={(evt, editor) =>
                                              (StudentTestPageCxt.editorRef.current =
                                                editor)
                                            }
                                            init={{
                                              resize: false,
                                              readonly: true,
                                              menubar: false,
                                              statusbar: false,
                                              license_key: "gpl",
                                              plugins: "autoresize",
                                            }}
                                          />
                                        </div>
                                      )}
                                    <div className="commontest-mainpage-single-multiple-question-divider-div"></div>
                                  </div>
                                </>
                              ))}
                            </div>
                          ))}
                        </div>
                      }
                    </>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
